export { FacturasService } from './facturas/facturas.service';
export { GaleriaService } from './galeria/galeria.service';
export { ElnotarioService } from './elnotario/elnotario.service';
export { PreguntasService } from './preguntas/preguntas.service';
export { InformacionService } from './informacion/informacion.service';
export { EventosService } from './eventos/eventos.service';
export { PaginasService } from './paginas/paginas.service';
export { MenuService } from './menu/menu.service';
export { EntidadesService } from './entidades/entidades.service';
export { VideosService } from './videos/videos.service';
export { NoticiasService } from './noticias/noticias.service';
export { SliderService } from './slider/slider.service';
export { SharedService } from './shared/shared.service';
export { BreadcrumbsService } from './shared/breadcrumbs.service';



