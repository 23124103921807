import { Validators } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Mensajes } from '../../models/mensajes';
import { QuejasService } from '../../services/quejas/quejas.service';
import swal from 'sweetalert';




@Component({
  selector: 'app-quejas',
  templateUrl: './trabajacnl.component.html',
  styles: []
})
export class TrabajacnlComponent implements OnInit {

  formas: FormGroup;
  
  tipodocumentos: string[] = ['Seleccione...','Testimonio', 'Parte Notarial', 'Busqueda','Otros'];
  default: string = 'Seleccione..';    
  
  exnotarios: string[] = ['Seleccione...','Carcausto Tapia Selmo Iván',	
  'Carnero Ávalos, Clara Palmira Ysabel',	
  'Correa Miller, Gustavo Antonio',
  'De Osamblea Lynch, Jose Manuel Sigifredo',
  'Espinosa-Garreta, Ramón A.',
  'Forero Garcia-Calderón, Manuel',
  'Gonzáles-Vigil Balbuena, Percy',
  'Landázuri Golffer, Cyra Ana',
  'Landi Grillo, Juan Gustavo',
  'Murguía Cavero, Jaime Alejandro',
  'Orihuela Ibérico, Jorge Eduardo',
  'Ortiz de Zevallos Villarán, Ricardo',
  'Patrón Balarezo, Germán Augusto',
  'Pinedo Orrillo, Walter Ramón',
  'Salinas Ampuero, Juan Carlos',
  'Sánchez-Manrique Tavella David',
  'Scamarone Muñoz, Néstor Adolfo',
  'Schiaffino de Villanueva, Carmen Liova',
  'Tito Villena, Amador Alejandro',
  'Utor Quiñe,José Diego'];

  
  constructor(private quejasService: QuejasService) { 
    
    this.formas = new FormGroup({
      tipodocumento: new FormControl(this.tipodocumentos[0]),
      exnotario: new FormControl(this.default)
  });

  this.formas.controls['tipodocumento'].setValue(this.tipodocumentos[0], 
  {onlySelf: true});
  this.formas.get('tipodocumento');

  this.formas.controls['exnotario'].setValue(this.default, 
    {onlySelf: true});
    this.formas.get('exnotario');
    
  }

  ngOnInit() {
    this.formas = new FormGroup({
      documento: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('[0-9]+')]),
      nombres: new FormControl(null, Validators.required),
      correo: new FormControl(null, [Validators.required, Validators.email]),
      telefono: new FormControl(null, Validators.required),
      acto: new FormControl(null, Validators.required),
      fechaescritura: new FormControl(null, Validators.required),
      tipodocumento: new FormControl(this.tipodocumentos[0], Validators.required),
      exnotario: new FormControl(this.exnotarios[0], Validators.required),
      interviniente: new FormControl(null, Validators.required),
     
    });
  
  }

    enviarMensaje(){
    
  
    let mensaje = new Mensajes(

       this.formas.value.documento,
       this.formas.value.nombres,
       this.formas.value.correo,
       this.formas.value.telefono,
       this.formas.value.acto,
       this.formas.value.fechaescritura,
       this.formas.value.tipodocumento,
       this.formas.value.exnotario,
       this.formas.value.interviniente 
       );
      
    this.quejasService.enviarMensajexnotario(mensaje).subscribe(response=>{
      swal('Mensaje', response.message, response.status);
     
      this.formas.reset();
    }, error=>{
      console.log(error);
    });

  }

}
