import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

// MODULOS
import { PagesComponent } from './pages.component';
import { SharedModule } from '../shared/shared.module';

// RUTAS
import { PAGES_ROUTES } from './pages.route';

// MAPS;
import { AgmCoreModule } from '@agm/core';

// PIPE
import { PipesModule } from '../pipes/pipes.module';

// PAGINADO
import { NgxPaginationModule } from 'ngx-pagination';

// COMPONENTES
import { HomeComponent } from './home/home.component';
import { ContactoComponent } from './contacto/contacto.component';
import { BuscarComponent } from './buscar/buscar.component';
import { BuscarColegioComponent } from './buscar/buscar-colegio.component';
import { PaginasComponent } from './paginas/paginas.component';
import { InstitucionalComponent } from './institucional/institucional.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { CertificacionComponent } from './servicios/certificacion.component';
import { ProyeccionComponent } from './servicios/proyeccion.component';
import { NotvirtualComponent } from './notvirtual/notvirtual.component';
import { LegislacionComponent } from './legislacion/legislacion.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { PrensaNoticiasComponent } from './prensa-noticias/prensa-noticias.component';
import { PrensaBoletinesComponent } from './prensa-boletines/prensa-boletines.component';
import { PrensaEventosComponent } from './prensa-eventos/prensa-eventos.component';
import { DetalleNoticiaComponent } from './prensa-noticias/detalle-noticia.component';
import { BoletinDetalleComponent } from './prensa-boletines/boletin-detalle.component';
import { CongresoComponent } from './prensa-boletines/congreso.component';
import { PrensaVideosComponent } from './prensa-videos/prensa-videos.component';
import { VideoDetalleComponent } from './prensa-videos/video-detalle.component';
import { PreguntasComponent } from './preguntas/preguntas.component';
import { QuejasComponent } from './quejas/quejas.component';
import { ExnotariosComponent } from './quejas/exnotarios.component';
import { TrabajacnlComponent } from './quejas/trabajacnl.component';
import { DetalletrabajoComponent } from './quejas/detalletrabajo.component';
import { DetalletrabajonewComponent } from './quejas/detalletrabajonew.component';
import { DetalletrabajoprogramadorComponent } from './quejas/detalletrabajoprogramador.component';
import { ElnotarioComponent } from './elnotario/elnotario.component';

import { ConadministradorComponent } from './elnotario/conadministrador.component';
import { ConsecretribunalComponent } from './elnotario/consecretribunal.component';

import { EventolibroComponent } from './elnotario/eventolibro.component';


import { ConvproyComponent } from './elnotario/Convproy.component';
import { ConcursoocpComponent } from './elnotario/concursoocp.component';

import { MatrimonioComponent } from './elnotario/matrimonio.component';

import { CeasComponent } from './elnotario/ceas.component';
import {ReconocimientohijoComponent } from './elnotario/reconociminetohijo.component';

import { CongresoiComponent } from './elnotario/congresoi.component';
import { ReniecComponent } from './elnotario/reniec.component';

import { FuncionesComponent } from './elnotario/funciones.component';




import { ConvocatoriaComponent } from './elnotario/convocatoria.component';
import { ConcursocnlComponent } from './elnotario/concursocnl.component';
import { ConcursofloresComponent } from './elnotario/concursoflores.component';

import { ComunicadoComponent } from './elnotario/comunicado.component';

import { ComunicadosComponent } from './elnotario/comunicados.component';

import { ComunicadostribComponent } from './elnotario/comunicadostrib.component';

import { ComunicaComponent } from './elnotario/comunica.component';
import { ComunicacionComponent } from './elnotario/comunicacion.component';
import { GaleriaComponent } from './galeria/galeria.component';

import { EventosAcademicosComponent } from './eventosacademicos/eventosacademicos.component';


import { GaleriaDetalleComponent } from './galeria/galeria-detalle.component';


import { PrensaListadoComponent } from './prensa-noticias/prensa-listado.component';
import { BuscarDistritoComponent } from './buscar/buscar-distrito.component';
import { MovilComponent } from './movil/movil.component';
import { DeporteComponent } from './deporte/deporte.component';
import { DeporteGaleriaComponent } from './deporte/deporte-galeria/deporte-galeria.component';
import { DiscursosComponent } from './discursos/discursos.component';
import { NotariosComponent } from './notarios/notarios.component';
import { NotariosTodosComponent } from './notarios/notarios-todos/notarios-todos.component';

// ANGULAR MATERIAL
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatIconModule,
  MatInputModule,
} from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ListaPreciosComponent } from './notarios/lista-precios/lista-precios.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { ListaPrecioComponent } from './notarios/lista-precio/lista-precio.component';
import { ListaContactosComponent } from './notarios/lista-contactos/lista-contactos.component';
import { LibrosComponent } from './libros/libros.component';
import { LibroDetallesComponent } from './libros/libro-detalles/libro-detalles.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    PAGES_ROUTES,
    NgxPaginationModule,
    PipesModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDigMHnT7rjb8HJ2eRGJ5X7quReCwA075Q"
    }),
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatTreeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
  ],
  declarations: [
    PagesComponent,
    HomeComponent,
    ContactoComponent,
    BuscarComponent,
    BuscarColegioComponent,
    PaginasComponent,
    InstitucionalComponent,
    ServiciosComponent,
    CertificacionComponent,
    ProyeccionComponent,
    NotvirtualComponent,
    LegislacionComponent,
    ProyectosComponent,
    PrensaNoticiasComponent,
    PrensaBoletinesComponent,
    PrensaEventosComponent,
    DetalleNoticiaComponent,
    BoletinDetalleComponent,
    CongresoComponent,
    PrensaVideosComponent,
    VideoDetalleComponent,
    PreguntasComponent,
    QuejasComponent,
    ExnotariosComponent,
    TrabajacnlComponent,
    DetalletrabajoComponent,
    DetalletrabajonewComponent,
    DetalletrabajoprogramadorComponent,
    ElnotarioComponent,
    ConadministradorComponent,
    ConsecretribunalComponent,
    EventolibroComponent,
    ConvproyComponent,
    ConcursoocpComponent,
    MatrimonioComponent,
    CeasComponent,
    ReconocimientohijoComponent,
    CongresoiComponent,
    ReniecComponent,
    ConvocatoriaComponent,
    ConcursocnlComponent,
    ConcursofloresComponent,
    FuncionesComponent,
    ComunicaComponent,
    ComunicadoComponent,
    ComunicadosComponent,
    ComunicadostribComponent,
    ComunicacionComponent,
    GaleriaComponent,
    GaleriaDetalleComponent,
    EventosAcademicosComponent,
    PrensaListadoComponent,
    BuscarDistritoComponent,
    MovilComponent,
    DeporteComponent,
    DeporteGaleriaComponent,
    DiscursosComponent,
    NotariosComponent,
    NotariosTodosComponent,
    ListaPreciosComponent,
    ListaPrecioComponent,
    ListaContactosComponent,
    LibrosComponent,
    LibroDetallesComponent,
  ],
  exports: [
    PagesComponent,
    HomeComponent,
    ContactoComponent,
    BuscarComponent,
    BuscarColegioComponent,
    PaginasComponent,
    InstitucionalComponent,
    ServiciosComponent,
    CertificacionComponent,
    ProyeccionComponent,
    NotvirtualComponent,
    LegislacionComponent,
    ProyectosComponent,
    PrensaNoticiasComponent,
    PrensaBoletinesComponent,
    PrensaEventosComponent,
    DetalleNoticiaComponent,
    BoletinDetalleComponent,
    CongresoComponent,
    PrensaVideosComponent,
    VideoDetalleComponent,
    PreguntasComponent,
    QuejasComponent,
    ExnotariosComponent,
    TrabajacnlComponent,
    DetalletrabajoComponent,
    DetalletrabajonewComponent,
    DetalletrabajoprogramadorComponent,
    ElnotarioComponent,
    EventolibroComponent,
    ConvproyComponent,
    ConcursoocpComponent,
    MatrimonioComponent,
    CeasComponent,
    ReconocimientohijoComponent,
    CongresoiComponent,
    ReniecComponent,
    ConvocatoriaComponent,
    FuncionesComponent,
    ConcursocnlComponent,
    ConcursofloresComponent,
    ComunicadoComponent,
    ComunicadosComponent,
    ComunicadostribComponent,
    ComunicacionComponent,
    ComunicaComponent,
    GaleriaComponent,
    GaleriaDetalleComponent,

    EventosAcademicosComponent,

    PrensaListadoComponent,
    BuscarDistritoComponent,
    MovilComponent,
    DeporteComponent,
    DeporteGaleriaComponent,
    DiscursosComponent,
    NotariosComponent,
    NotariosTodosComponent,
    ListaPreciosComponent,
    ListaPrecioComponent,
    ListaContactosComponent,
    LibrosComponent,
    LibroDetallesComponent,
  ],
  schemas: []
})
export class PagesModule { }
