import { Component, OnInit, DoCheck } from '@angular/core';
import { NotariosService } from './../../../services/notarios/notarios.service';
import { Notarios } from './../../../models/notarios';
import { EventosService } from './../../../services/eventos/eventos.service';
declare function init_slider();

@Component({
  selector: 'app-notarios-todos',
  templateUrl: './notarios-todos.component.html',
  styles: []
})
export class NotariosTodosComponent implements OnInit, DoCheck {
  notarios: Notarios[] = [];
  eventos: any[] = [];
  mostrar: string;
  total: number = 0;

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
    previousLabel: 'Anterior',
    nextLabel: 'Siguiente'
  };

  constructor(
    public notariosService: NotariosService,
    private eventosService: EventosService
  ) { }

  ngOnInit() {
    this.obtenerEventos();
    this.cargarNotarios();
    init_slider();
  }

  obtenerEventos() {
    this.eventosService.cargarEventos().subscribe(response => {
      this.eventos = response.eventos;
      this.eventos.forEach(event => {
        if (event.mostrar === 1) {
          this.mostrar = "1";
        } else {
          this.mostrar = "0";
        }
      });
    });
  }

  ngDoCheck() {
    init_slider();
  }

  cargarNotarios() {
    this.notariosService.cargarNotariosTodos().subscribe(response => {
      this.notarios = response.notarios;
      this.total = response.total;
    });
  }

  buscarNotarioTodos(termino: string) {
    if (termino != '') {
      this.notariosService.buscarNotarioTodos(termino).subscribe(response => {
        this.notarios = response.notarios;
        this.total = response.total;
      });
    } else {
      this.cargarNotarios();
    }
  }

  buscarNotariosEstado(estado: string) {
    if (estado != "3") {
      this.notariosService.buscarNotarioEstado(estado).subscribe(response => {
        this.notarios = response.notarios;
        this.total = response.total;
      });
    } else {
      this.cargarNotarios();
    }
  }
}
