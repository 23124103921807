export class Galeria {
  constructor(
    public id?:number,
    public user_id?: number,
    public titulo?: string,
    public imagen?: string,
    public fecha?: string,
    public detalle?: string,
    public orden?:string,
    public status?:number,
    public created_at?:string,
    public updated_at?:string
  ) {}
}
