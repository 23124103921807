export class Quejas {
  constructor(
    public nombres?: string,
    public dni?:number,
    public correo?: string,
    public asunto?:string,
    public telefono?:string,
    public mensaje?:string,
    public fecha?:string
  ) {}
}
