import { Injectable } from '@angular/core';
import { urlBackend } from 'src/app/models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Deporte } from './../../models/deporte';

@Injectable({
  providedIn: "root"
})
export class DeporteService {
  url: string = urlBackend;
  deporte: Deporte = new Deporte();

  constructor(
    private http: HttpClient
  ) {}

  cargarDeporte(): Observable<any> {
    return this.http.get(this.url + "deporte");
  }


  cargarDeporteDetalle(deporteId: number): Observable<any> {
    return this.http.get(this.url + "deporteDetalle/" + deporteId);
  }

  cargarDeporteGaleria(deporteId: number): Observable<any> {
    return this.http.get(this.url + "deporteGaleria/" + deporteId);
  }

  cargarDeporteGaleriaDetalle(galeriaId: number): Observable<any> {
    return this.http.get(this.url + "deporteGaleriaDetalle/" + galeriaId);
  }
}
