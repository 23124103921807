export class FacturasEnviadas {
  constructor(
    public id?:number,
    public facturaId?: number,
    public nombreArchivo?: string,
    public numeroControl?: string,
    public pdf?: any,
    public sizePDF?: number,
    public exito?: boolean,
    public nombreOriginal?: string,
    public tramaXmlFirmado?: string,
    public sizeXML?: any
  ) {}
}
