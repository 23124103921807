import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { urlBackend } from '../../models/GLOBAL';

@Injectable()
export class SharedService {

  url:string = urlBackend;

  constructor(public http: HttpClient) {
    this.getNotarios();
    this.getColegios();
  }

  getNotarios():Observable<any>{
    return this.http.get(this.url + "notarios");
  }

  getColegios():Observable<any>{
    return this.http.get(this.url + "colegios");
  }

  buscarNotarioLike(termino:string):Observable<any>{
    return this.http.get(this.url + "notarios/like/" + termino);
  }

  buscarColegio(termino:string):Observable<any>{
    return this.http.get(this.url + "colegios/like/" + termino);
  }

  listarNotariosXcolegios(sisev: number):Observable<any>{
    return this.http.get(this.url + "colegios/" + sisev);
  }

  buscarNotariosAll(termino1:string, termino2:string):Observable<any>{
    return this.http.get(this.url + "notarios/all/" + termino1 + '/' + termino2);
  }

  buscarNotarioXColegio(termino:string, termino2:string):Observable<any>{
    return this.http.get(this.url + "colegios/" + termino + "/" + termino2);
  }

  buscarGrupos(terminos:string, terminos2:string):Observable<any>{
    return this.http.get(this.url + "grupos/" + terminos + "/" + terminos2);
  }

  getDistritos():Observable<any>{
    return this.http.get(this.url + "distritos");
  }

  getDistritosnuevo(sisev: number):Observable<any>{
    return this.http.get(this.url + "distritos/"+sisev);
  }



  getDistritosnuevocolegio(sisev: number):Observable<any>{
    return this.http.get(this.url + "distritoscolegio/"+sisev);
  }

  buscarNotarioXDistrito(nomdis:string):Observable<any>{
    return this.http.get(this.url + "distritos/" + nomdis);
  }

  buscarNotarioXDistritonueo(nomdis:any):Observable<any>{
    return this.http.get(this.url + "notariosiddistrito/" + nomdis);
  }

  buscarNotarioXDistritonueocodigo(nomdis:any):Observable<any>{
    return this.http.get(this.url + "notariosiddistritocodigo/" + nomdis);
  }
  
  buscarDistrito(nomdis:string):Observable<any>{
    return this.http.get(this.url + "distritos/like/" + nomdis);
  }

}
