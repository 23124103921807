export class Discurso {
  constructor(
    public id?:number,
    public user_id?: number,
    public titulo?: string,
    public imagen?: string,
    public archivo?: string,
    public video?: string,
    public imagenVideo?: string,
    public videoImage?:string,
    public detalle?:string,
    public created_at?:string,
    public updated_at?:string
  ) {}
}
