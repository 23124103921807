import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { RouterModule } from '@angular/router';

import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { HeaderComponent } from './header/header.component';
import { TopbarComponent } from './topbar/topbar.component';
import { NavComponent } from './nav/nav.component';
import { SliderComponent } from './slider/slider.component';
import { IconComponent } from './icon/icon.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { EventosComponent } from './eventos/eventos.component';
import { BoletinesComponent } from './boletines/boletines.component';
import { VideoComponent } from './video/video.component';
import { EntidadesComponent } from './entidades/entidades.component';
import { FooterComponent } from './footer/footer.component';

// PIPE
import { PipesModule } from '../pipes/pipes.module';

// COMPONENTES
import { BusquedaNotarialComponent } from '../components/busqueda-notarial/busqueda-notarial.component';
import { DiscursoComponent } from './discurso/discurso.component';
import { ImportanteComponent } from './importante/importante.component';
import { MovilTemplateComponent } from './movil-template/movil-template.component';

// ANGULAR MATERIAL
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material';
import { DemoMaterialModule } from './material-module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    DemoMaterialModule,
    PipesModule,
  ],
  declarations: [
    HeaderComponent,
    TopbarComponent,
    NavComponent,
    SliderComponent,
    IconComponent,
    NoticiasComponent,
    EventosComponent,
    BoletinesComponent,
    VideoComponent,
    EntidadesComponent,
    FooterComponent,
    BusquedaNotarialComponent,
    BreadcrumbsComponent,
    MovilTemplateComponent,
    DiscursoComponent,
    ImportanteComponent,
  ],
  exports: [
    HeaderComponent,
    TopbarComponent,
    NavComponent,
    SliderComponent,
    IconComponent,
    NoticiasComponent,
    EventosComponent,
    BoletinesComponent,
    VideoComponent,
    EntidadesComponent,
    FooterComponent,
    BusquedaNotarialComponent,
    BreadcrumbsComponent,
    MovilTemplateComponent,
    DiscursoComponent,
    ImportanteComponent
  ],
  entryComponents: [BusquedaNotarialComponent],
  bootstrap: [BusquedaNotarialComponent],
  providers: []
})
export class SharedModule { }
