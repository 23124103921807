import { SharedService } from '../../services/service.index';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

export interface Colegio {
  colegios: string;
}
export interface Distritos {
  nomdis: string;
}
export interface Notarios {
  notario: string;
}
@Component({
  selector: 'app-busqueda-notarial',
  templateUrl: './busqueda-notarial.component.html',
  styles: []
})
export class BusquedaNotarialComponent implements OnInit {

  myColegios = new FormControl();
  myDistritos = new FormControl();
  myNotarios = new FormControl();

  optionsColegios : Colegio[] = [];
  optionsDistritos : Distritos[] = [];
  optionsNotarios : Notarios[] = [];
  nomdis:any;
  filteredColegios: Observable<Colegio[]>;
  filteredDistritos: Observable<Distritos[]>;
  filteredNotarios: Observable<Notarios[]>;
  filteredNotariosnuevo: Observable<Notarios[]>;

  // NOTARIOS
  notarios: string[] = [];

  constructor(public shared:SharedService, private router:Router) {
  }

  ngOnInit() {
    this.cargarColegios();
   this.cargarDistritos();
   this.cargarNotarios(22);
  }

  cargarColegios(){
    this.shared.getColegios().subscribe(response=>{
      this.optionsColegios = response.colegios;
      this.filteredColegios = this.myColegios.valueChanges
      .pipe(
        startWith<string | Colegio>(''),
        map(value => typeof value === 'string' ? value : value.colegios),
        map(colegios => colegios ? this._filterColegios(colegios) : this.optionsColegios.slice())
      );
    });
  }

  cargarDistritos(){
    this.shared.getDistritos().subscribe(response=>{
      this.optionsDistritos = response.distritos;
      this.filteredDistritos = this.myDistritos.valueChanges
      .pipe(
        startWith<string | Distritos>(''),
        map(value => typeof value === 'string' ? value: value.nomdis),
        map(distritos => distritos ? this._filterDistritos(distritos) : this.optionsDistritos.slice())
      );
    });
  }

  cargarNotarios(sisev: number){
    

  
    this.shared.getDistritosnuevocolegio(sisev).subscribe(response=>{
      this.optionsDistritos = response.notarios;
      this.filteredDistritos = this.myDistritos.valueChanges
      .pipe(
        startWith<string | Distritos>(''),
        map(value => typeof value === 'string' ? value: value.nomdis),
        map(distritos => distritos ? this._filterDistritos(distritos) : this.optionsDistritos.slice())
      );
    });
  }

  displayColegios(colegio?: Colegio): string | undefined {
    return colegio ? colegio.colegios : undefined;
  }

  displayDistritos(distrito?: Distritos): string | undefined {
    return distrito ? distrito.nomdis : undefined;
  }

  displayNotarios(notario?: Notarios): string | undefined {
    return notario ? notario.notario : undefined;
  }

  private _filterColegios(colegios: string): Colegio[] {
    const filterValue = colegios.toLowerCase();
    this.shared.getColegios().subscribe(response=>{
      this.optionsColegios = response.colegios;
    });
    return this.optionsColegios.filter(option => option.colegios.toLowerCase().indexOf(filterValue) >= 0);
  }

  private _filterDistritos(distritos: string): Distritos[] {
    const filterValue = distritos.toLowerCase();
    this.shared.getDistritos().subscribe(response=>{
      this.optionsDistritos = response.distritos;
    });
    return this.optionsDistritos.filter(option => option.nomdis.toLowerCase().indexOf(filterValue) >= 0);
  }

  private _filterNotarios(notarios: string): Notarios[] {
    const filterValue = notarios.toLowerCase();
    this.shared.buscarNotarioXDistritonueo(this.nomdis).subscribe(response=>{
      this.optionsNotarios = response.notarios;
    });
    return this.optionsNotarios.filter(option => option.notario.toLowerCase().indexOf(filterValue) >= 0);
  }

  mostrarNotarios(option: any){
    this.cargarNotarios(option.id);
  }

  mostrarNotariosDistritos(option:any){

   // this.router.navigate(['distrito', distritoInput]);
    this.nomdis=option;

    console.log('this.nomdis');
    console.log(this.nomdis);
    this.cargarNotariospordistrito(this.nomdis);

  }



  cargarNotariospordistrito(nomdis: number){
    this.shared.buscarNotarioXDistritonueocodigo(nomdis).subscribe(response=>{
      this.optionsNotarios = response.notarios;
      this.filteredNotarios = this.myNotarios.valueChanges
      .pipe(
        startWith<string | Notarios>(''),
        map(value => typeof value === 'string' ? value: value.notario),
        map(notarios => notarios ? this._filterNotarios(notarios): this.optionsNotarios.slice())
      );
    });

  /* 
    this.shared.getDistritosnuevocolegio(sisev).subscribe(response=>{
      this.optionsDistritos = response.notarios;
      this.filteredDistritos = this.myDistritos.valueChanges
      .pipe(
        startWith<string | Distritos>(''),
        map(value => typeof value === 'string' ? value: value.nomdis),
        map(distritos => distritos ? this._filterDistritos(distritos) : this.optionsDistritos.slice())
      );
    }); */

  }


  filtrarNotario(colegio: string, notario: string){
 
    if (colegio != '') {
      if (notario != '') {
        this.router.navigate(['colegios', colegio, notario]);
      }
    } else {
      this.router.navigate(['notarios', notario]);
    }
  }

}
