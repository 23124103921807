export class Deporte {
  constructor(
    public id?:number,
    public user_id?: number,
    public portada?: string,
    public contacto?: string,
    public correo?:string,
    public telefono?:string,
    public celular?: string,
    public created_at?:string,
    public updated_at?:string
  ) {}
}
