import { Videos } from '../../models/videos';
import { Component, OnInit } from '@angular/core';
import { VideosService } from '../../services/service.index';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styles: []
})
export class VideoComponent implements OnInit {

  videos: Videos[] = [];
  total: number;
  url: string;
  opciones: string = '?rel=0&amp;wmode=transparent';

  constructor(public videosService: VideosService) {
    this.url = videosService.url + 'storage/videos/';
  }

  ngOnInit() {
    this.videosService.cargarVideosPortada().subscribe(response=>{
      this.videos = response.videos;
      this.total = response.total;
    });
  }

}
