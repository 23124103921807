import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { Facturas } from '../models/facturas';
import { FacturasService } from '../services/facturas/facturas.service';
import { FacturasEnviadas } from '../models/facturasEnviadas';
declare var $:any;
import swal from 'sweetalert';

@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styles: []
})
export class FacturasComponent implements OnInit {

  factura: Facturas = new Facturas(null, '', '', '0', '', '', '', '', '', '', '', '', '', '', 2, '', '', '');
  facturasEnviadas : FacturasEnviadas = new FacturasEnviadas();
  cargando: boolean = true;
  today: string;

  constructor(public facturasService: FacturasService, public router:Router) {
    let now = new Date();
    let day = ("0" + now.getDate()).slice(-2);
    let month = ("0" + (now.getMonth() + 1)).slice(-2);
    this.today = now.getFullYear() + "-" + month + "-" + day;
  }

  ngOnInit() {
    this.factura.fecfactura = this.today;
    $("input[name=numdoc]").attr('readonly', true);
  }

  descargarXML(factura: Facturas){

    if (this.factura.tipodocum == "0") {
      swal('', 'Seleccione el tipo de documento.');
      return;
    }

    if (this.factura.numdoc == "") {
      swal("", "Ingrese el número de documento.");
      return;
    }

    this.facturasService.getFacturas(this.factura).subscribe(response => {
      if (response.listado != null) {
        this.facturasEnviadas = response.listado;

        this.facturasService.getFile(this.facturasEnviadas.facturaId, this.facturasEnviadas.nombreArchivo, 2).subscribe(responsePDF => {
          let blob = new File([responsePDF], this.facturasEnviadas.nombreArchivo, { type: 'contentType' });
          saveAs(blob, this.facturasEnviadas.nombreArchivo + '.xml');
        }, error => {
          console.log(error);
        });

      } else {
        swal('', 'No se encontraron resultados, intente nuevamente.', 'info');
      }

    }, error => {
      console.log(error);
    });
  }

  verFacturas(forma: NgForm){
    if (forma.invalid) {
      if(this.factura.tipodocum == "0"){
        swal('', 'Seleccione el tipo de documento.');
      }
      return;
    }

    this.facturasService.getFacturas(this.factura).subscribe(response=>{
      if(response.listado != null){
        this.facturasEnviadas = response.listado;

        this.facturasService.getFile(this.facturasEnviadas.facturaId, this.facturasEnviadas.nombreArchivo, 1).subscribe(responsePDF=>{
          let blob  = new File([responsePDF], this.facturasEnviadas.nombreArchivo, { type: 'contentType' });
          saveAs(blob, this.facturasEnviadas.nombreArchivo + '.pdf');
          }, error=>{
          console.log(error);
        });

      }else{
        swal('', 'No se encontraron resultados, intente nuevamente.', 'info');
      }

    }, error=>{
      console.log(error);
    });
  }

  habilitarCampo(){
    if(this.factura.tipodocum !== '0'){
      $("input[name=numdoc]").attr('readonly', false);
    }else{
      $("input[name=numdoc]").attr('readonly', true);
      $("input[name=numdoc]").val('');
    }
  }

}
