import { Galeria } from './galeria';

export class ImagenesGaleria {
  constructor(
    public id?:number,
    public imagen?: string,
    public orden?:string,
    public created_at?:string,
    public updated_at?:string,
    public galeria?: Galeria,
    public titulo?: string
  ) {}
}
