export class Facturas {
  constructor(
    public id?:number,
    public fecfactura?: string,
    public nroDocumento?: string,
    public tipodocum?: string,
    public numdoc?: string,
    public estadofactu?: string,
    public fecvto?: string,
    public moneda?: string,
    public fechatipocambio?: string,
    public tipocambio?: string,
    public subTotal?: string,
    public igv?: string,
    public totaPagar?: string,
    public seriesunat?: string,
    public estadoenvio?: number,
    public observacion?: string,
    public fechaIni?: string,
    public fechaFin?: string
  ) {}
}
