import { Component, OnInit, DoCheck } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginasService } from '../../services/service.index';
import { EventosService } from '../../services/eventos/eventos.service';
import { Eventos } from '../../models/eventos';
declare function init_slider();

@Component({
  selector: 'app-certifiacion',
  templateUrl: './certificacion.component.html',
  styles: []
})
export class CertificacionComponent implements OnInit, DoCheck {

  paginas: any[] = [];
  url: string;
  mostrar: string;
  eventos: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private paginaService: PaginasService,
    private eventosService: EventosService
  ) {
    this.url = paginaService.url + 'storage/entidades/';
  }

  ngOnInit() {
    this.obtenerEventos();
    this.activatedRoute.params.subscribe(params => {
      let tipoMenu = params['id'];
      let pagina = params['pagina'];
      if (pagina === "0") {
        this.mostrarPaginaMenu(tipoMenu);
      } else if (pagina === "1") {
        this.mostrarPaginaSubMenu(tipoMenu);
      }
    });
    init_slider();
  }

  ngDoCheck() {
    init_slider();
  }

  mostrarPaginaSubMenu(id: string) {
    this.paginaService.cargarPaginasSubMenu(id).subscribe(response => {
      this.paginas = response.paginas;
    });
  }

  mostrarPaginaMenu(id: string) {
    this.paginaService.cargarPaginasMenu(id).subscribe(response => {
      this.paginas = response.paginas;
    });
  }

  obtenerEventos() {
    this.eventosService.cargarEventos().subscribe(response => {
      this.eventos = response.eventos;
      this.eventos.forEach(event => {
        if (event.mostrar === 1) {
          this.mostrar = "1";
        } else {
          this.mostrar = "0";
        }
      });
    });
  }

}
