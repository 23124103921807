import { Validators } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit,EventEmitter } from '@angular/core';
import { Mensajes } from '../../models/mensajes';
import { QuejasService } from '../../services/quejas/quejas.service';
import swal from 'sweetalert';




@Component({
  selector: 'app-quejas',
  templateUrl: './detalletrabajonew.component.html',
  styles: []
})
export class DetalletrabajonewComponent implements OnInit {


  propagar = new EventEmitter<string>();
  formas: FormGroup;
  public archivoSubir: File;
  public archivoTemp: any;
  tipodocumentos: string[] = ['Seleccione...','Testimonio', 'Parte Notarial', 'Busqueda','Otros'];
  default: string = 'Seleccione..';    
  tamanio_archivo=0;
  puesto:any;

  
  constructor(private quejasService: QuejasService) { 
    
    this.formas = new FormGroup({
      tipodocumento: new FormControl(this.tipodocumentos[0]),
      exnotario: new FormControl(this.default)
  });

  this.formas.controls['tipodocumento'].setValue(this.tipodocumentos[0], 
  {onlySelf: true});
  this.formas.get('tipodocumento');

  this.formas.controls['exnotario'].setValue(this.default, 
    {onlySelf: true});
    this.formas.get('exnotario');
    
  }

  ngOnInit() {
    this.tamanio_archivo=10;
    this.formas = new FormGroup({
      documento: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('[0-9]+')]),
      nombres: new FormControl(null, Validators.required),
      correo: new FormControl(null, [Validators.required, Validators.email]),
      telefono: new FormControl(null, Validators.required)
    });
  
  }
  archivo = {
    nombre: null,
    nombreArchivo: "(Sin documento adjunto)",
    base64textString:""
  }
  
  _handleReaderLoaded(readerEvent:any) {
    var binaryString = readerEvent.target.result;
    this.archivo.base64textString = btoa(binaryString);
  }
  public seleccionarArchivo(event:any){


    let imageError = "";
    let bit=1048576;
        const max_size = (this.tamanio_archivo*bit);//2.8 MB
        let xformat_des="";
        let xformat="PDF";
        let arrFormat;
     
        
        const allowed_types = ["application/pdf"];
        const max_height = 15200;
        const max_width = 25600;
        if (event.target.files[0].size > max_size) 
            imageError ='Máximo de tamaño es ' + this.tamanio_archivo + 'Mb';
        if (!allowed_types.includes(event.target.files[0].type)) 
            imageError = 'Solo se aceptan los siguientes formatos: ('+xformat+')';
            
     if(imageError!="")
     {
       alert(imageError);
       return;
     }
    var files = event.target.files;
    var file = files[0];
    this.archivo.nombreArchivo = file.name;

    if(files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }

   /*  if (!archivo) {
      this.archivoSubir = null;
      return;
    }

    if (archivo.type.split("/")[1] != 'pdf' &&
        archivo.type.split("/")[1] != 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        archivo.type.split("/")[1] != 'vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
      swal('Solo Documentos PDF, Excel o Word', 'El archivo seleccionado no es un documento permitido.', 'error');
      this.archivoSubir = null;
      return;

    }

    this.archivoSubir = archivo;

    let reader = new FileReader();
    let urlArchivoTemp = reader.readAsDataURL(archivo);

    reader.onloadend = ()=> this.archivoTemp = reader.result; */

  }


    enviarMensaje(){
    
  
      console.log(this.archivo);

    let mensaje = new Mensajes(
       this.formas.value.documento,
       this.formas.value.nombres,
       this.formas.value.correo,
       this.formas.value.telefono
       );
       this.puesto="Auxiliar Contable";
     this.quejasService.enviarMensajexnotarionuevo(mensaje,this.archivo,this.puesto).subscribe(response=>{
      swal('Mensaje', response.message, response.status);
     
      this.formas.reset();
    }, error=>{
      console.log(error);
    });

  }



  
}
