import { Component, OnInit, DoCheck } from '@angular/core';
import { Galeria } from '../../models/galeria';
import { GaleriaService, EventosService } from '../../services/service.index';
declare function init_slider();

@Component({
  selector: 'app-EventosAcademicos',
  templateUrl: './eventosacademicos.component.html',
  styles: []
})
export class EventosAcademicosComponent implements OnInit, DoCheck {

  galerias: Galeria[] = [];
  galeriasPortada: Galeria[] = [];
  total: number;
  url: string;
  mostrar:string;
  eventos: any[] = [];

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
        previousLabel: 'Anterior',
        nextLabel: 'Siguiente'
    };

  constructor(
    public galeriaService: GaleriaService,
    public eventosService: EventosService
  ) {
    this.url = galeriaService.urlUpload + 'galeria/';
  }

  ngOnInit() {
    this.cargarGaleria();
    this.obtenerEventos();
    this.cargarGaleriaPortada();
    init_slider();
  }

  ngDoCheck(){
    init_slider();
  }

  cargarGaleria(){
    this.galeriaService.cargarGaleria().subscribe(response=>{
      this.galerias = response.galerias;
      this.total = response.total;
    });
  }

  cargarGaleriaPortada(){
    this.galeriaService.cargarGaleriaPortada().subscribe(response=>{
      this.galeriasPortada = response.galerias;
      this.total = response.total;
      init_slider();
    });
  }

  obtenerEventos(){
    this.eventosService.cargarEventos().subscribe(response=>{
      this.eventos = response.eventos;
      this.eventos.forEach(event=>{
        if (event.mostrar === 1) {
          this.mostrar = "1";
        }else{
          this.mostrar = "0";
        }
      });
    });
  }

}
