import { Component, OnInit } from '@angular/core';
import { Empresas } from '../../models/empresas';
import { urlBackendUpload } from '../../models/GLOBAL';
import { EmpresasService } from '../../services/empresas/empresas.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent implements OnInit {

  empresas: Empresas = new Empresas();
  url: string = urlBackendUpload + 'empresas/';
  mostarnuevo :any;
  constructor(public empresasService: EmpresasService) { }

  fecha:any = new Date();

  urlFacebook: string = 'https://www.facebook.com/plugins/page.php?href=http%3A%2F%2Fwww.facebook.com%2Fpages%2FColegio-de-Notarios-de-Lima%2F156048227742661&tabs=timeline&width=532&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId';

  ngOnInit() {
    this.empresasService.cargarEmpresas().subscribe(response => {
      this.empresas = response.empresas;
    });
  }

}
