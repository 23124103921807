import { Injectable } from '@angular/core';
import { urlBackend } from '../../models/GLOBAL';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PreguntasService {

  url: string = urlBackend;

  constructor(public http: HttpClient) { }

  cargarPreguntas():Observable<any>{
    return this.http.get(this.url + 'preguntas');
  }
}
