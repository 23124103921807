import { Injectable } from '@angular/core';
import { urlBackend } from 'src/app/models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class IconosService {
  url: string = urlBackend;

  constructor(public http: HttpClient) {}

  cargarIconos():Observable<any>{
    return this.http.get(this.url + 'iconos');
  }
}
