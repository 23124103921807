import { Component, OnInit, DoCheck } from '@angular/core';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Noticia } from './../../models/noticias';
import { NoticiasService } from 'src/app/services/service.index';
import { EventosService } from './../../services/eventos/eventos.service';
declare function init_slider();

@Component({
  selector: "app-prensa-listado",
  templateUrl: "./prensa-listado.component.html",
  styles: []
})
export class PrensaListadoComponent implements OnInit, DoCheck {
  url: string = urlBackendUpload + "noticias/";
  mostrar: string;
  eventos: any[] = [];
  noticias: Noticia;
  Anios:any[] = [];
  Meses:any[] = [];
  Noticias:Noticia[] = [];

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
    previousLabel: '',
    nextLabel: ''
  };

  constructor(
    public noticiasService: NoticiasService,
    private eventosService: EventosService
  ) {}

  ngOnInit() {
    this.obtenerEventos();
    init_slider();
    this.cargarAnios();
    this.cargarMeses();
    this.cargarNoticias();
  }

  ngDoCheck() {
    init_slider();
  }

  obtenerEventos() {
    this.eventosService.cargarEventos().subscribe(response => {
      this.eventos = response.eventos;
      this.eventos.forEach(event => {
        if (event.mostrar === 1) {
          this.mostrar = "1";
        } else {
          this.mostrar = "0";
        }
      });
    });
  }

  cargarAnios(){
    this.noticiasService.listarAnios().subscribe(response=>{
      this.Anios = response.anios;
    });
  }

  cargarMeses(){
    this.noticiasService.listarMeses().subscribe(response => {
      this.Meses = response.meses;
    });
  }

  cargarNoticias(){
    this.noticiasService.listarNoticias().subscribe(response=>{
      this.noticias = response.noticias;
    });
  }
}
