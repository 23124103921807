import { Component, OnInit, DoCheck } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotariosService } from '../../../services/notarios/notarios.service';
import { Contacto } from 'src/app/models/contacto';
import { Notarios } from 'src/app/models/notarios';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { SharedService } from 'src/app/services/service.index';
declare function init_slider();
declare var swal: any;

@Component({
  selector: 'app-lista-contactos',
  templateUrl: './lista-contactos.component.html',
  styles: []
})
export class ListaContactosComponent implements OnInit, DoCheck {

  contactos: Contacto[] = [];
  abogados: Contacto[] = [];
  total: number = 0;
  totalAbogado: number = 0;
  notario: Notarios = new Notarios();

  contactoArray: any[] = [];
  contactoFor: any[] = [];

  abogadoArray: any[] = [];
  abogadoFor: any[] = [];

  url: string = urlBackendUpload + 'listaprecios/';

  constructor(
    public activatedRoute: ActivatedRoute,
    public notariosService: NotariosService,
    public router: Router,
    private route:ActivatedRoute,
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      let codigo = params["id"];
      this.obtenerContactos(codigo);
    });
  }

  obtenerContactos(notariId: number) {
    this.notariosService.cargarContactos(notariId).subscribe(response => {
      this.contactos = response.contactos;
      this.abogados = response.abogados;
      this.notario = response.notario;
      this.total = response.total;
      this.totalAbogado = response.totalAbogado;

      if (this.totalAbogado == 0 && this.total == 0) {
        this.router.navigate(['/listaprecio/22/1']);
        swal('No se encontraron datos');
      }

      if (this.totalAbogado > 0) {

        this.abogados.forEach(element => {
          this.abogadoArray.push(element.nombres.split('//'));
        });

        for (let index = 0; index < this.abogadoArray['0'].length; index++) {

          if (this.abogadoArray['0'][index] == undefined) {
            this.abogadoFor.push({
              'nombres': '',
              'colegiatura': '',
              'correo': '',
              'telefono': ''
            });
          } else {

            let correo: string;
            if (this.abogadoArray['0'][index].split('/')[2] != undefined) {
              correo = this.abogadoArray['0'][index].split('/')[2];
            } else if(this.abogados[index] != undefined){
              correo = this.abogados[index].correo;
            } else {
              correo = '';
            }

            let telefono: string;
            if (this.abogadoArray['0'][index].split('/')[3] != undefined) {
              telefono = this.abogadoArray['0'][index].split('/')[3];
            } else if(this.abogados[index] != undefined){
              telefono = this.abogados[index].telefono;
            } else {
              telefono = '';
            }


            this.abogadoFor.push({
              'nombres': this.abogadoArray['0'][index].split('/')[0],
              'colegiatura': this.abogadoArray['0'][index].split('/')[1],
              'correo': correo,
              'telefono': telefono
            });
          }
        }
      }


      if (this.total > 0) {
        this.contactos.forEach(element => {
          this.contactoArray.push(element.nombres.split('//'));
        });

        for (let index = 0; index < this.contactoArray['0'].length; index++) {

          if (this.contactoArray['0'][index] == undefined) {
            this.contactoFor.push({
              'nombres': '',
              'colegiatura': '',
              'correo': '',
              'telefono': ''
            });
          } else {

            let correo: string;
            if (this.contactoArray['0'][index].split('/')[2] != undefined) {
              correo = this.contactoArray['0'][index].split('/')[2];
            } else if (this.contactos[index] != undefined){
              correo = this.contactos[index].correo;
            } else {
              correo = '';
            }

            let telefono: string;
            if (this.contactoArray['0'][index].split('/')[3] != undefined) {
              telefono = this.contactoArray['0'][index].split('/')[3];
            } else if(this.contactos[index] != undefined){
              telefono = this.contactos[index].telefono;
            } else {
              telefono = '';
            }

            this.contactoFor.push({
              'nombres': this.contactoArray['0'][index].split('/')[0],
              'colegiatura': this.contactoArray['0'][index].split('/')[1],
              'correo': correo,
              'telefono': telefono
            });
          }
        }
      }

    });
  }

  descargarPDF(colegiatura: string) {
    window.open(this.url + colegiatura + '.pdf', '_blank');
  }

  cargarMapa() {
    this.route.params.subscribe(parametros=>{
      this.notariosService.obtenerNotario(parametros['id']).subscribe(response=>{
        this.notario = response.colegios;
      });
    });
  }

  ngDoCheck() {
    init_slider();
  }

}
