import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../services/service.index';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-buscar-colegio',
  templateUrl: './buscar-colegio.component.html',
  styles: []
})
export class BuscarColegioComponent implements OnInit {

  notarios:any[] = [];
  latitud: number = -12.0839839;
  longitud: number = -77.0534367;
  zoom: number;
  total:number;

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
        previousLabel: 'Anterior',
        nextLabel: 'Siguiente'
    };

  constructor(private router:Router, private shared:SharedService, private route:ActivatedRoute, private domSanitizer:DomSanitizer) { }

  ngOnInit() {
    this.route.params.subscribe(parametros=>{
      this.shared.buscarNotarioXColegio(parametros['terminos'], parametros['terminos2']).subscribe(response=>{
        this.notarios = response.colegios;
        this.total = response.total;

        this.notarios.forEach( not => {
          this.latitud = not.latitud;
          this.longitud = not.longitud;

          if (response.total === 1) {
            this.zoom = 16;
          }else if(response.total === 2){
            this.zoom = 12;
          }else if(response.total === 3){
            this.zoom = 8;
          }else if(response.total === 4){
            this.zoom = 6;
          }else if(response.total === 5){
            this.zoom = 4;
          }else if(response.total > 5){
            this.zoom = 10;
          }
        });

      });
    });
  }

  buscarMapa(item){
    this.latitud = item.latitud;
    this.longitud = item.longitud;
    this.zoom = 18;
  }
}
