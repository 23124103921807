import { Component, OnInit, DoCheck } from '@angular/core';
import { ElnotarioService } from '../../services/elnotario/elnotario.service';
import { EventosService } from '../../services/eventos/eventos.service';
declare function init_slider();

@Component({
  selector: 'app-elnotario',
  templateUrl: './concursocnl.component.html',
  styles: []
})
export class ConcursocnlComponent implements OnInit, DoCheck {

  elnotarios:any[] = [];
  total:number;
  mostrar:string;
  eventos: any[] = [];

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
        previousLabel: 'Anterior',
        nextLabel: 'Siguiente'
    };

  constructor(
    private elnotarioService: ElnotarioService,
    private eventosService: EventosService
  ) { }

  ngOnInit() {
    this.obtenerEventos();
    this.elnotarioService.cargarElnotario().subscribe(response=>{
      this.elnotarios = response.elnotarios;
      this.total = response.total;
      init_slider();
    });
  }

  ngDoCheck(){
    init_slider();
  }

  obtenerEventos(){
    this.eventosService.cargarEventos().subscribe(response=>{
      this.eventos = response.eventos;
      this.eventos.forEach(event=>{
        if (event.mostrar === 1) {
          this.mostrar = "1";
        }else{
          this.mostrar = "0";
        }
      });
    });
  }

}
