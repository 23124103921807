import { urlBackendVisual } from '../../models/GLOBAL';
import { Injectable } from '@angular/core';
import { Facturas } from '../../models/facturas';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FacturasService {

  url:string = urlBackendVisual;

  constructor(public http: HttpClient) { }

  getFacturas(facturas: Facturas): Observable<any>{
    let json = { "tipoDocum": facturas.tipodocum, "numDoc": facturas.numdoc, "fecfactura": facturas.fecfactura, "TotaPagar": facturas.totaPagar};
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    let params = JSON.stringify(json);
    return this.http.post(this.url + 'Caja/GetFacturas', params, {headers: headers});
  }

  getFile(Id: number, filename:string, typeDocuemtn:number):Observable<any>{
    return this.http.get(this.url + 'Caja/DownloadFile/'+Id+'?Filename='+filename+'&TypeDocument='+typeDocuemtn, { responseType: "arraybuffer"});
  }
}
