import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

// MODULOS
import { PagesModule } from './pages/pages.module';

// RUTAS
import { app_routing } from './app.routes';

// NO PAGES FOUND
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';

// Servicios
import { ServiceModule } from './services/service.module';
import { FacturasComponent } from './facturas/facturas.component';

@NgModule({
  declarations: [
    AppComponent,
    NopagefoundComponent,
    FacturasComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    PagesModule,
    app_routing,
    ServiceModule,
    CommonModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [FacturasComponent]
})
export class AppModule { }
