import { Component, OnInit, DoCheck } from '@angular/core';
import { BoletinesService } from '../../services/boletines/boletines.service';
import { Boletin } from '../../models/boletin';
import { EventosService } from '../../services/eventos/eventos.service';
import { Eventos } from '../../models/eventos';
declare function init_slider();

@Component({
  selector: 'app-prensa-boletines',
  templateUrl: './prensa-boletines.component.html',
  styles: []
})
export class PrensaBoletinesComponent implements OnInit, DoCheck {

  boletines: Boletin;
  total: number;
  mostrar:string;
  eventos: any[] = [];

  constructor(
    public boletinService: BoletinesService,
    private eventosService: EventosService
  ) { }

  ngOnInit() {
    this.obtenerEventos();
    this.cargarBoletin();
    init_slider();
  }

  ngDoCheck(){
    init_slider();
  }

  cargarBoletin(){
    this.boletinService.cargarBoletinesnuevo().subscribe(response=>{
      this.boletines = response.boletines;
      this.total = response.total;
    });
  }

  obtenerEventos(){
    this.eventosService.cargarEventos().subscribe(response=>{
      this.eventos = response.eventos;
      this.eventos.forEach(event=>{
        if (event.mostrar === 1) {
          this.mostrar = "1";
        }else{
          this.mostrar = "0";
        }
      });
    });
  }

}
