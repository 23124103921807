import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginasService } from '../../services/service.index';

@Component({
  selector: 'app-proyectos',
  templateUrl: './proyectos.component.html',
  styles: []
})
export class ProyectosComponent implements OnInit {

  paginas: any[] = [];
  url:string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private paginaService: PaginasService
  ) {
    this.url = paginaService.url + 'storage/entidades/';
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
      let tipoMenu = params['id'];
      let pagina = params['pagina'];
      if (pagina === "0") {
        this.mostrarPaginaMenu(tipoMenu);
      }else if(pagina === "1"){
        this.mostrarPaginaSubMenu(tipoMenu);
      }
     });
  }

  mostrarPaginaSubMenu(id:string){
    this.paginaService.cargarPaginasSubMenu(id).subscribe(response=>{
       this.paginas = response.paginas;
    });
  }

  mostrarPaginaMenu(id:string){
    this.paginaService.cargarPaginasMenu(id).subscribe(response=>{
      this.paginas = response.paginas;
    });
  }
}
