import { Component, OnInit, DoCheck } from '@angular/core';
import { Precios } from 'src/app/models/precios';
import { NotariosService } from '../../../services/notarios/notarios.service';
import { Notarios } from 'src/app/models/notarios';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Router } from '@angular/router';
declare function init_slider(): any;
declare var $: any;
declare var swal: any;
@Component({
  selector: 'app-lista-precio',
  templateUrl: './lista-precio.component.html',
  styles: []
})

export class ListaPrecioComponent implements OnInit, DoCheck {
  data: any[] = [];
  total: number = 0;
  url: string = urlBackendUpload + 'listaprecios/';
  distritos: any[] = [];

  totalContacto: number = 0;
  totalAbogado: number = 0;

  precios: Precios[] = [];
  notarios: Notarios[] = [];

  panelOpenState = false;

  constructor(
    public notariosServices: NotariosService,
    public router: Router
  ) { }

  ngOnInit() {
    init_slider();
    this.cargarNotarios();
    this.cargarDistritos();
  }

  ngDoCheck() {
    init_slider();
  }

  cargarNotarios() {
    this.notariosServices.listaNotarios().subscribe(response => {
      this.notarios = response.notarios;
      this.total = response.total;
    });
  }

  buscarNotario(termino: string) {
    if (termino != '') {
      this.notariosServices.buscarNotarioNuevo(termino).subscribe(response => {
        this.notarios = response.notarios;
        this.total = response.total;
      });
    } else {
      this.cargarNotarios();
    }
  }

  buscarNotarioDistrito(termino: string) {
    if (termino != '0') {
      this.notariosServices.buscarNotarioDistrito(termino).subscribe(response => {
        this.notarios = response.notarios;
        this.total = response.total;
      });
    } else {
      this.cargarNotarios();
    }
  }

  cargarDistritos() {
    this.notariosServices.cargarDistritos().subscribe(response => {
      this.distritos = response.ubigeos;
    });
  }

  obtenerServicios(notarioId: number) {

    if (this.panelOpenState === true) {
      $("#" + notarioId).addClass('color-expand');
    } else {
      $("#" + notarioId).removeClass('color-expand');
    }
  }

  descargarPDF(colegiatura: number, notarioId: number) {

    this.notariosServices.cargarContactos(notarioId).subscribe(response => {
      this.totalContacto = response.total;
      this.totalAbogado = response.totalAbogado;

      if (this.totalAbogado == 0 && this.totalContacto == 0) {
        this.router.navigate(['/listaprecio/22/1']);
        swal('No se encontraron datos');
      } else {
        window.open(this.url + colegiatura + '.pdf', '_blank');
      }
    });

  }

}
