import { Component, OnInit, DoCheck } from '@angular/core';
import { BoletinesService } from '../../services/boletines/boletines.service';
import { BoletinDetalle } from '../../models/boletinDetalle';
import { ActivatedRoute } from '@angular/router';
import { urlBackend } from '../../models/GLOBAL';
import { EventosService } from '../../services/eventos/eventos.service';
import { Eventos } from '../../models/eventos';
declare function init_slider();

@Component({
  selector: 'app-boletin-detalle',
  templateUrl: './boletin-detalle.component.html',
  styles: []
})
export class BoletinDetalleComponent implements OnInit, DoCheck {

  url:string;
  mostrar:string;
  eventos: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private boletinService: BoletinesService,
    private eventosService: EventosService
  ) {
    this.url = boletinService.urlUpload + 'boletines/';
  }

  boletinDetalles: BoletinDetalle;
  titulo: string;
  total: number;
  numeroboletin: number;
  numeroboletinnuevo:number;

  ngOnInit() {
    this.obtenerEventos();
    this.activatedRoute.params.subscribe(params=>{
     let codigo = params['id'];
      this.obtenerBoletin(codigo);
      this.boletinService.obtenerBoletinDetalle(codigo).subscribe(response=>{
        this.boletinDetalles = response.boletines;
        this.numeroboletin = this.boletinDetalles[0].boletin_id;
        this.total = response.total;
        init_slider();
      });
    });
  }

  ngDoCheck(){
    init_slider();
  }

  obtenerBoletin(id:string){
    this.boletinService.obtenerBoletin(id).subscribe(response=>{
      this.titulo = response.boletines.titulo;
    });
  }

  obtenerEventos(){
    this.eventosService.cargarEventos().subscribe(response=>{
      this.eventos = response.eventos;
     console.log(response.eventos);
      this.eventos.forEach(event=>{
        if (event.mostrar === 1) {
          this.mostrar = "1";
        }else{
          this.mostrar = "0";
        }
      });
    });
  }

}
