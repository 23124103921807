export class Mensajes {
  constructor(
    public documento?:string,
    public nombres?: string,
    public correo?: string,
    public telefono?:string,
    public archivo?:any,
    public acto?:string,
    public fechaescritura?:string,
    public tipodocumento?:string,
    public exnotario?:string, 
    public interviniente?:string,
    public archivoSubir?:any,
    
  ) {}
}
