import { Component, OnInit, DoCheck } from '@angular/core';
import { EventosService } from '../../services/eventos/eventos.service';
import { urlBackendUpload } from '../../models/GLOBAL';
import { LegislacionService } from '../../services/legislacion/legislacion.service';
import { Legislacion } from '../../models/legislacion';
import { Categoria } from './../../models/categoria';
import { ActivatedRoute } from '@angular/router';
import { PaginasService } from '../../services/paginas/paginas.service';
declare function init_slider();

@Component({
  selector: "app-legislacion",
  templateUrl: "./legislacion.component.html",
  styles: []
})
export class LegislacionComponent implements OnInit, DoCheck {
  legislaciones: Legislacion[] = [];
  legislacion: Legislacion = new Legislacion();
  categorias: Categoria[] = [];
  categoria: Categoria = new Categoria();
  urlArchivo: string = urlBackendUpload + "legislacion/";
  mostrar: string;
  nuevomostrar: string;
  eventos: any[] = [];
  busqueda: boolean = true;
  paginas: any[] = [];

  constructor(
    private legislacionService: LegislacionService,
    private eventosService: EventosService,
    private paginaService: PaginasService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.obtenerEventos();
    
    //
    this.activatedRoute.params.subscribe(params=>{
      let tipoMenu = params['id'];
      let pagina = params['pagina'];
      this.nuevomostrar=params['id'];
      //console.log('holaaaaaa'+ tipoMenu +'ffff'+pagina );
      if (pagina === "0") {
        this.mostrarPaginaMenu(tipoMenu);
      }else if(pagina === "1"){
        this.mostrarPaginaSubMenu(tipoMenu);
        
      }
     
     });
    //
    init_slider();
  }

  mostrarPaginaSubMenu(id:string){
    this.paginaService.cargarPaginasSubMenu(id).subscribe(response=>{
       this.paginas = response.paginas;
    });
  }
  mostrarPaginaMenu(id:string){
    this.paginaService.cargarPaginasMenu(id).subscribe(response=>{
      this.paginas = response.paginas;
    });
  }

  obtenerEventos() {
    this.eventosService.cargarEventos().subscribe(response => {
      this.eventos = response.eventos;
     // console.log('hopla'+ this.nuevomostrar);
      this.cargarCategorias();
      this.eventos.forEach(event => {
        if (event.mostrar === 1) {
          this.mostrar = "1";

        } else {
          this.mostrar = "0";
        }
      });
    });
  }

  ngDoCheck() {
    init_slider();
  }

  cargarCategorias() {
    this.legislacionService.cargarCategorias().subscribe(response => {
      this.categorias = response.categorias;
      this.obtenerLegislacion();
    });
  }

  obtenerLegislacion() {
    this.legislacionService.legislacionXCategoria().subscribe(responseCat => {
      this.legislaciones = responseCat.legislaciones;
    });
  }

  buscarTitulo(event) {
    if (event != "") {
      this.legislacionService.busqueda(event).subscribe(response => {
        this.busqueda = false;
        this.legislacion = response.legislaciones;
        this.categoria = response.categorias;
      });
    } else {
      this.busqueda = true;
      this.cargarCategorias();
    }
  }
}
