import { Component, OnInit, DoCheck } from '@angular/core';
import { Discurso } from '../../models/discurso';
import { DiscursoService } from '../../services/discurso/discurso.service';
import { EventosService } from '../../services/eventos/eventos.service';
import { urlBackend, urlBackendUpload } from 'src/app/models/GLOBAL';
import { ActivatedRoute } from '@angular/router';
declare function init_slider();

@Component({
  selector: "app-discursos",
  templateUrl: "./congreso.component.html",
  styles: []
})
export class CongresoComponent implements OnInit, DoCheck {
  discursos: Discurso = new Discurso();
  mostrar: string;
  eventos: any[] = [];
  url: string = urlBackendUpload + "discursos/";
  opciones: string = "?rel=0&amp;wmode=transparent";

  constructor(
    private activatedRoute: ActivatedRoute,
    public discursoService: DiscursoService,
    public eventosService: EventosService
  ) {
    
      this.url = discursoService.urlUpload + 'congreso/';
    
  }

  ngOnInit() {
    this.obtenerEventos();
    this.activatedRoute.params.subscribe(params=>{
      let codigo = params['boletin.id'];

      this.cargarDiscurso(codigo);
      this.discursoService.obtenercongreso(codigo).subscribe(response=>{
        this.discursos = response.discursos;
        console.log(this.discursos.archivo);
        init_slider();
      });
    });

    init_slider();
  }

  ngDoCheck() {
    init_slider();
  }

  cargarDiscurso(id:string) {
    this.discursoService.obtenercongreso(id).subscribe(response => {
      this.discursos = response.discursos;
      console.log(this.discursos.archivo);
    });
  }

  obtenerEventos() {
    this.eventosService.cargarEventos().subscribe(response => {
      this.eventos = response.eventos;
      this.eventos.forEach(event => {
        if (event.mostrar === 1) {
          this.mostrar = "1";
        } else {
          this.mostrar = "0";
        }
      });
    });
  }
}
