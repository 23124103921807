import { Component, OnInit, DoCheck } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginasService } from '../../services/paginas/paginas.service';
import { EventosService } from '../../services/eventos/eventos.service';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
declare function init_slider();

@Component({
  selector: 'app-institucional',
  templateUrl: './institucional.component.html',
  styles: []
})
export class InstitucionalComponent implements OnInit, DoCheck {

  paginas: any[] = [];
  url:string = urlBackendUpload + 'entidades/';
  mostrar:string;
  eventos: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private paginaService: PaginasService,
    private eventosService: EventosService
  ) {
  }

  ngOnInit() {
    this.obtenerEventos();
    this.activatedRoute.params.subscribe(params=>{
      let tipoMenu = params['id'];
      let pagina = params['pagina'];
      if (pagina === "0") {
        this.mostrarPaginaMenu(tipoMenu);
      }else if(pagina === "1"){
        this.mostrarPaginaSubMenu(tipoMenu);
      }
     });
     init_slider();
  }

  ngDoCheck(){
    init_slider();
  }

  mostrarPaginaSubMenu(id:string){
    this.paginaService.cargarPaginasSubMenu(id).subscribe(response=>{
      
       this.paginas = response.paginas;
    });
  }

  mostrarPaginaMenu(id:string){
    this.paginaService.cargarPaginasMenu(id).subscribe(response=>{
      this.paginas = response.paginas;
    });
  }

  obtenerEventos(){
    this.eventosService.cargarEventos().subscribe(response=>{
      this.eventos = response.eventos;
      console.log(response.eventos);
      console.log("response.eventos");
      this.eventos.forEach(event=>{
        if (event.mostrar === 1) {
          this.mostrar = "1";
        }else{
          this.mostrar = "0";
        }
      });
    });
  }

}
