import { Injectable } from '@angular/core';
import { urlBackend, urlBackendUpload } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BoletinesService {

  url: string = urlBackend;
  urlUpload: string = urlBackendUpload;

  constructor(public http: HttpClient) { }

  cargarBoletines():Observable<any>{
    return this.http.get(this.url + 'boletinlist');
  }

  cargarBoletinesnuevo():Observable<any>{
    return this.http.get(this.url + 'boletinespecialnuevo');
  }
  cargarEspeciales():Observable<any>{
    return this.http.get(this.url + 'boletinespecial');
  }

  obtenerBoletin(id:string):Observable<any>{
    return this.http.get(this.url + 'boletinMostrar/' + id);
  }

  obtenerBoletinDetalle(id:string):Observable<any>{
    return this.http.get(this.url + 'boletinDetalle/' + id);
  }

//jr
  
}
