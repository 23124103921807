import { Injectable } from '@angular/core';
import { urlBackend, urlBackendUpload } from 'src/app/models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs-compat';

@Injectable({
  providedIn: 'root'
})
export class DiscursoService {

  url: string = urlBackend;
  urlUpload: string = urlBackendUpload;

  constructor(public http: HttpClient) { }

  cargarDiscurso():Observable<any>{
    return this.http.get(this.url + 'discursos/1');
  }

  cargarDatos():Observable<any>{
    return this.http.get(this.url + 'mostrar/2');
  }
  obtenercongreso(id:string):Observable<any>{
    return this.http.get(this.url + 'discursos/' + id);
  }

  obtenercongresonuevo(id:string):Observable<any>{
    return this.http.get(this.url + 'boletincongresonuevo/' + id);
  }
}
