import { Component, OnInit } from '@angular/core';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { DeporteService } from './../../../services/deporte/deporte.service';
import { DeporteGaleriaDetalle } from 'src/app/models/deporteGaleriaDetalle';
import { ActivatedRoute, Router } from '@angular/router';
declare function init_imagen();
declare var $:any;

@Component({
  selector: 'app-deporte-galeria',
  templateUrl: './deporte-galeria.component.html',
  styles: []
})
export class DeporteGaleriaComponent implements OnInit {

  url: string=urlBackendUpload + 'deporteGaleriaDetalle/';
  galeriaDetalle: DeporteGaleriaDetalle[] = [];
  total:number = 0;

  constructor(
    public deporteService: DeporteService,
    public activatedRoute: ActivatedRoute,
    public router: Router
    ) { }

  ngOnInit() {
    init_imagen();
    this.activatedRoute.params.subscribe(params=>{
      let galeriaId = params['id'];
      this.cargarDeporteGaleriaDetalle(galeriaId);
    });

  }

  cargarDeporteGaleriaDetalle(galeriaId:number){
    this.deporteService.cargarDeporteGaleriaDetalle(galeriaId).subscribe(response=>{
      this.galeriaDetalle = response.deportes;
      this.total = response.total;

      setTimeout(() => {
        $(".acm").nivoLightbox({
          effect: "fade",
          theme: "default",
          keyboardNav: !0,
          errorMessage:
            "El contenido solicitado no se puede cargar. Por favor, inténtelo de nuevo más tarde."
        });
      }, 1);
    });
  }

}
