import { Informacion } from '../../models/informacion';
import { Component, OnInit } from '@angular/core';
import { InformacionService } from '../../services/service.index';
import { BoletinesService } from '../../services/boletines/boletines.service';
import { Boletin } from '../../models/boletin';
import { BoletinDetalle } from '../../models/boletinDetalle';
import { DeporteService } from './../../services/deporte/deporte.service';
import { Deporte } from 'src/app/models/deporte';
import { Mostrar } from './../../models/mostrar';
import { Router } from '@angular/router';
import { urlBackendUpload } from 'src/app/models/GLOBAL';

@Component({
  selector: 'app-boletines',
  templateUrl: './boletines.component.html',
  styles: []
})
export class BoletinesComponent implements OnInit {

  informacion: Informacion[] = [];
  boletines: Boletin[] = [];
  especiales: Boletin[] = [];
  boletinDetalle: BoletinDetalle[] = [];
  deporte: Deporte = new Deporte();
  total: number;
  totalBoletin: number = 0;
  totalEspecial: number = 0;
  url: string = urlBackendUpload + 'informacion/';
  urlBoletin:string = urlBackendUpload + "boletines/";
  urlDeporte:string = urlBackendUpload + 'deporte/';
  mostrar: Mostrar = new Mostrar();

  constructor(
    public informacionService: InformacionService,
    public boletinesService: BoletinesService,
    public deporteService: DeporteService,
    public router: Router
  ) {}

  ngOnInit() {
    this.cargarInformacion();
    this.cargarBoletines();
    this.cargarEspeciales();
    this.cargarDatos();
    this.cargarDeporte();
  }

  cargarDeporte(){
    this.deporteService.cargarDeporte().subscribe(response=>{
      this.deporte = response.deportes;
    });
  }

  cargarDatos(){
    this.informacionService.cargarDatos().subscribe(response=>{
      this.mostrar = response.mostrar;
    });
  }

  cargarInformacion(){
    this.informacionService.cargarInformacion().subscribe(response=>{
      this.informacion = response.informacion;
      this.total = response.total;
    });
  }

  cargarBoletines(){
    this.boletinesService.cargarBoletines().subscribe(response=>{
      this.especiales = response.boletines;
      this.totalBoletin = response.total;
    });
  }
  cargarEspeciales(){
    this.boletinesService.cargarEspeciales().subscribe(response=>{
      this.boletines = response.boletines;
      this.totalEspecial = response.total;
    });
  }
}
