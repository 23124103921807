import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LegislacionService } from './legislacion/legislacion.service';

import {
  SharedService,
  BreadcrumbsService,
  SliderService,
  NoticiasService,
  VideosService,
  EntidadesService,
  MenuService,
  PaginasService,
  EventosService,
  InformacionService,
  PreguntasService,
  ElnotarioService,
  GaleriaService,
  FacturasService,
} from './service.index';
import { DeporteService } from './deporte/deporte.service';
import { IconosService } from './iconos/iconos.service';
import { DiscursoService } from './discurso/discurso.service';
import { NotariosService } from './notarios/notarios.service';
import { LibrosService } from './libros/libros.service';
import { EmpresasService } from './empresas/empresas.service';

 @NgModule({
 imports: [
     CommonModule,
     HttpClientModule
  ],
  providers: [
    SharedService,
    BreadcrumbsService,
    SliderService,
    NoticiasService,
    VideosService,
    EntidadesService,
    MenuService,
    PaginasService,
    EventosService,
    InformacionService,
    PreguntasService,
    ElnotarioService,
    GaleriaService,
    FacturasService,
    LegislacionService,
    DeporteService,
    IconosService,
    DiscursoService,
    NotariosService,
    LibrosService,
    EmpresasService
  ],
  declarations: []
})
export class ServiceModule { }
