import { Categoria } from './categoria';
export class Legislacion {
  constructor(
    public id?:number,
    public user_id?: number,
    public categoria_id?: number,
    public titulo?: string,
    public detalle?: string,
    public archivo?: string,
    public fecha?: string,
    public orden?:number,
    public status?:number,
    public created_at?:string,
    public updated_at?:string,
    public categoria?: Categoria
  ) {}
}
