import { Component, OnInit, DoCheck } from '@angular/core';
import { Precios } from 'src/app/models/precios';
import { NotariosService } from '../../../services/notarios/notarios.service';

import { NestedTreeControl } from '@angular/cdk/tree';
import { of } from 'rxjs';
import { Notarios } from 'src/app/models/notarios';
declare function init_slider();
declare var $: any;

interface TestData {
  name: string;
  children?: TestData[];
}

const GetChildren = (node: TestData) => of(node.children);
const TC = new NestedTreeControl(GetChildren);

@Component({
  selector: 'app-lista-precios',
  templateUrl: './lista-precios.component.html',
  styles: []
})

export class ListaPreciosComponent implements OnInit, DoCheck {

  tc = TC;
  data: any[] = [];
  total: number = 0;

  hasChild(_: number, node: TestData) {
    return node.children != null && node.children.length > 0;
  }

  precios: Precios[] = [];
  notarios: Notarios[] = [];

  panelOpenState = false;

  constructor(public notariosServices: NotariosService) { }

  ngOnInit() {
    init_slider();
    this.cargarNotarios();
  }

  ngDoCheck() {
    init_slider();
  }

  cargarNotarios() {
    this.notariosServices.listarPreciosOpcion().subscribe(response => {
      this.notarios = response.notarios;
      this.total = response.total;
    });
  }

  buscarNotario(termino: string) {
    if (termino != '') {
      this.notariosServices.buscarNotario(termino).subscribe(response => {
        this.notarios = response.notarios;
        this.total = response.total;
      });
    } else {
      this.cargarNotarios();
    }
  }

  obtenerServicios(notarioId: number) {

    if (this.panelOpenState == true) {
      $("#" + notarioId).addClass('color-expand');
    } else {
      $("#" + notarioId).removeClass('color-expand');
    }

    this.notariosServices.obtenerNotarioServicioOpcion(notarioId).subscribe(response => {
      this.precios = response.detalles;

      let servicios: any[] = [];

      this.precios.forEach((prec: any) => {

        let index: number = servicios.findIndex(obj => obj.name == prec.nodo1);

        if (index == -1) {

          if (prec.nodo2 == null || prec.nodo2 == '') {

            servicios.push({
              'name': prec.nodo1,
              'price': prec.precio,
              'abrev': prec.abrev,
              'expand_more': '',
              'chevron_right': '',
              'border': true
            });

          } else {

            if (prec.nodo3 == null || prec.nodo3 == '') {

              servicios.push({
                'name': prec.nodo1,
                'expand_more': 'expand_more',
                'chevron_right': 'chevron_right',
                'border': false,
                'children': [{
                  'name': prec.nodo2,
                  'price': prec.precio,
                  'abrev': prec.abrev,
                  'expand_more': '',
                  'chevron_right': '',
                  'border': true
                }]
              });

            } else {

              servicios.push({
                'name': prec.nodo1,
                'expand_more': 'expand_more',
                'chevron_right': 'chevron_right',
                'border': false,
                'children': [{
                  'name': prec.nodo2,
                  'expand_more': 'expand_more',
                  'chevron_right': 'chevron_right',
                  'border': false,
                  'children': [{
                    'name': prec.nodo3,
                    'price': prec.precio,
                    'abrev': prec.abrev,
                    'expand_more': '',
                    'chevron_right': '',
                    'border': true
                  }]
                }]
              });

            }

          }

        } else {

          let index2: number = servicios[index].children.findIndex(obj => obj.name == prec.nodo2);

          if (index2 == -1) {

            if (prec.nodo3 == null || prec.nodo3 == '') {

              servicios[index].children.push({
                'name': prec.nodo2,
                'price': prec.precio,
                'abrev': prec.abrev,
                'expand_more': '',
                'chevron_right': '',
                'border': true
              });

            } else {

              servicios[index].children.push({
                'name': prec.nodo2,
                'expand_more': 'expand_more',
                'chevron_right': 'chevron_right',
                'border': false,
                'children': [{
                  'name': prec.nodo3,
                  'price': prec.precio,
                  'abrev': prec.abrev,
                  'expand_more': '',
                  'chevron_right': '',
                  'border': true
                }]
              });
            }

          } else {

            servicios[index].children[index2].children.push({
              'name': prec.nodo3,
              'price': prec.precio,
              'abrev': prec.abrev,
              'expand_more': '',
              'chevron_right': '',
              'border': true
            });
          }
        }
      });

      this.data = servicios;

    });
  }

}
