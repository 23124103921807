import { Component, OnInit, DoCheck } from '@angular/core';
import { NoticiasService } from '../../services/service.index';
import { Noticia } from '../../models/noticias';
import { EventosService } from '../../services/eventos/eventos.service';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
declare function init_slider();

@Component({
  selector: 'app-prensa-noticias',
  templateUrl: './prensa-noticias.component.html',
  styles: []
})
export class PrensaNoticiasComponent implements OnInit, DoCheck {

  noticias: Noticia;
  total: number;
  noticiasMeses: any[]= [];
  totalMeses: number;
  url: string = urlBackendUpload + 'noticias/';
  mostrar:string;
  eventos: any[] = [];

   // PAGINADO
   p: number = 1;
   public autoHide: boolean = true;
   public labels: any = {
         previousLabel: 'Anterior',
         nextLabel: 'Siguiente'
     };

  constructor(
    public noticiasService: NoticiasService,
    private eventosService: EventosService
  ) {}

  ngOnInit() {
    this.obtenerEventos();
    this.cargarNoticias();
    init_slider();
  }

  ngDoCheck(){
    init_slider();
  }

  cargarNoticias(){
    this.noticiasService.cargarNoticias().subscribe(response=>{
      this.noticias = response.noticias;
      this.total = response.total;
    });
  }

  obtenerEventos(){
    this.eventosService.cargarEventos().subscribe(response=>{
      this.eventos = response.eventos;
      this.eventos.forEach(event=>{
        if (event.mostrar === 1) {
          this.mostrar = "1";
        }else{
          this.mostrar = "0";
        }
      });
    });
  }

}
