import { Injectable } from '@angular/core';
import { urlBackend, urlBackendUpload } from 'src/app/models/GLOBAL';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Empresas } from 'src/app/models/empresas';

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {

  url: string = urlBackend;

  constructor(
    private http:HttpClient,
  ) { }
  cargarEmpresas(): Observable<any>{
    return this.http.get(this.url + "empresas");
  }
}
