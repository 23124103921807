import { Component, OnInit, DoCheck } from '@angular/core';
import { Discurso } from './../../models/discurso';
import { DiscursoService } from './../../services/discurso/discurso.service';
import { EventosService } from './../../services/eventos/eventos.service';
import { urlBackend, urlBackendUpload } from 'src/app/models/GLOBAL';
declare function init_slider();

@Component({
  selector: "app-discursos",
  templateUrl: "./discursos.component.html",
  styles: []
})
export class DiscursosComponent implements OnInit, DoCheck {
  discursos: Discurso = new Discurso();
  mostrar: string;
  eventos: any[] = [];
  url: string = urlBackendUpload + "discursos/";
  opciones: string = "?rel=0&amp;wmode=transparent";

  constructor(
    public discursoService: DiscursoService,
    public eventosService: EventosService
  ) {}

  ngOnInit() {
    this.obtenerEventos();
    this.cargarDiscurso();
    init_slider();
  }

  ngDoCheck() {
    init_slider();
  }

  cargarDiscurso() {
    this.discursoService.cargarDiscurso().subscribe(response => {
      this.discursos = response.discursos;
      console.log(this.discursos.archivo);
    });
  }

  obtenerEventos() {
    this.eventosService.cargarEventos().subscribe(response => {
      this.eventos = response.eventos;
      this.eventos.forEach(event => {
        if (event.mostrar === 1) {
          this.mostrar = "1";
        } else {
          this.mostrar = "0";
        }
      });
    });
  }
}
