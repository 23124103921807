import { RouterModule, Routes } from '@angular/router';

import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { ContactoComponent } from './contacto/contacto.component';
import { BuscarComponent } from './buscar/buscar.component';
import { BuscarColegioComponent } from './buscar/buscar-colegio.component';
import { InstitucionalComponent } from './institucional/institucional.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { CertificacionComponent } from './servicios/certificacion.component';
import { ProyeccionComponent } from './servicios/proyeccion.component';
import { NotvirtualComponent } from './notvirtual/notvirtual.component';
import { LegislacionComponent } from './legislacion/legislacion.component';
import { PrensaNoticiasComponent } from './prensa-noticias/prensa-noticias.component';
import { PrensaBoletinesComponent } from './prensa-boletines/prensa-boletines.component';
import { PrensaEventosComponent } from './prensa-eventos/prensa-eventos.component';
import { DetalleNoticiaComponent } from './prensa-noticias/detalle-noticia.component';
import { BoletinDetalleComponent } from './prensa-boletines/boletin-detalle.component';
import {CongresoComponent } from './prensa-boletines/congreso.component';
import { PrensaVideosComponent } from './prensa-videos/prensa-videos.component';
import { VideoDetalleComponent } from './prensa-videos/video-detalle.component';
import { PreguntasComponent } from './preguntas/preguntas.component';
import { QuejasComponent } from './quejas/quejas.component';
import { ExnotariosComponent} from './quejas/exnotarios.component';

import { TrabajacnlComponent} from './quejas/trabajacnl.component';
import { DetalletrabajoComponent} from './quejas/detalletrabajo.component';

import { DetalletrabajonewComponent} from './quejas/detalletrabajonew.component';
import { DetalletrabajoprogramadorComponent } from './quejas/detalletrabajoprogramador.component';

import { ElnotarioComponent } from './elnotario/elnotario.component';


import { ConadministradorComponent } from './elnotario/conadministrador.component';
import { ConsecretribunalComponent } from './elnotario/consecretribunal.component';

import { EventolibroComponent } from './elnotario/eventolibro.component';

import { ConvproyComponent } from './elnotario/Convproy.component';
import { ConcursoocpComponent } from './elnotario/concursoocp.component';


import { MatrimonioComponent } from './elnotario/matrimonio.component';

import { CeasComponent } from './elnotario/ceas.component';

import { ReconocimientohijoComponent } from './elnotario/reconociminetohijo.component';

import { CongresoiComponent } from './elnotario/congresoi.component';
import { ReniecComponent } from './elnotario/reniec.component';


import { ConvocatoriaComponent } from './elnotario/convocatoria.component';
import { ConcursocnlComponent } from './elnotario/concursocnl.component';
import { FuncionesComponent } from './elnotario/funciones.component';
import { ConcursofloresComponent } from './elnotario/concursoflores.component';

import { ComunicadoComponent } from './elnotario/comunicado.component';
import { ComunicadosComponent } from './elnotario/comunicados.component';


import { ComunicadostribComponent } from './elnotario/comunicadostrib.component';
import { ComunicaComponent } from './elnotario/comunica.component';
import { ComunicacionComponent } from './elnotario/comunicacion.component';
import { GaleriaComponent } from './galeria/galeria.component';
import { GaleriaDetalleComponent } from './galeria/galeria-detalle.component';


import { EventosAcademicosComponent } from './eventosacademicos/eventosacademicos.component';


import { PrensaListadoComponent } from './prensa-noticias/prensa-listado.component';
import { BuscarDistritoComponent } from './buscar/buscar-distrito.component';
import { MovilComponent } from './movil/movil.component';
import { DeporteComponent } from './deporte/deporte.component';
import { DeporteGaleriaComponent } from './deporte/deporte-galeria/deporte-galeria.component';
import { DiscursosComponent } from './discursos/discursos.component';
import { NotariosComponent } from './notarios/notarios.component';
import { NotariosTodosComponent } from './notarios/notarios-todos/notarios-todos.component';
import { ListaPreciosComponent } from './notarios/lista-precios/lista-precios.component';
import { ListaPrecioComponent } from './notarios/lista-precio/lista-precio.component';
import { ListaContactosComponent } from './notarios/lista-contactos/lista-contactos.component';
import { LibrosComponent } from './libros/libros.component';
import { LibroDetallesComponent } from './libros/libro-detalles/libro-detalles.component';

const pagesRoutes: Routes = [
  {
    path: "",
    component: PagesComponent,
    children: [
      {
        path: "home",
        component: HomeComponent,
        data: { titulo: "Colegio de Notarios de Lima" }
      },
      {
        path: "contacto",
        component: ContactoComponent,
        data: { titulo: "Mapa de Ubicación" }
      },
      {
        path: "notarios/:notarios",
        component: BuscarComponent,
        data: { titulo: "Búsqueda de Notarios" }
      },
      {
        path: "colegios/:terminos/:terminos2",
        component: BuscarColegioComponent,
        data: { titulo: "Búsqueda por Colegio" }
      },
      {
        path: "distrito/:nomdis",
        component: BuscarDistritoComponent,
        data: { titulo: "Búsqueda por Distrito" }
      },

      // PAGINAS
      {
        path: "institucional/:id/:pagina",
        component: InstitucionalComponent,
        data: { titulo: "Institucional" }
      },
      {
        path: "servicios/:id/:pagina",
        component: ServiciosComponent,
        data: { titulo: "Archivos de Ex Notarios" }
      },
      {
        path: "certificacion/:id/:pagina",
        component: CertificacionComponent,
        data: { titulo: "Certificación de Firmas" }
      },
      {
        path: "proyeccion/:id/:pagina",
        component: ProyeccionComponent,
        data: { titulo: "Proyección Social (CONST, EIRL, SAC, SA y ASOC)" }
      },
      {
        path: "notariavirtual/:id/:pagina",
        component: NotvirtualComponent,
        data: { titulo: "Notaria Virtual" }
      },
      {
        path: "legislacion/:id/:pagina",
        component: LegislacionComponent,
        data: { titulo: "Legislación" }
      },

      // NOTICIAS
      {
        path: "saladeprensa/:id/:pagina",
        component: PrensaListadoComponent,
        data: { titulo: "Notas de Prensa" }
      },
      {
        path: "noticias/:id/:pagina",
        component: PrensaNoticiasComponent,
        data: { titulo: "Notas de Prensa" }
      },
      {
        path: "detalleNoticias/:id",
        component: DetalleNoticiaComponent,
        data: { titulo: "Detalles de Notas de Prensa" }
      },

      {
        path: "boletines/:id/:pagina",
        component: PrensaBoletinesComponent,
        data: { titulo: "Boletín Institucional" }
      },
      {
        path: "detalleBoletines/:id",
        component: BoletinDetalleComponent,
        data: { titulo: "Resumen de Boletín" }
      },

      {
        path: "eventos/:id/:pagina",
        component: PrensaEventosComponent,
        data: { titulo: "Eventos Académicos" }
      },

      {
        path: "videos/:id/:pagina",
        component: PrensaVideosComponent,
        data: { titulo: "Videos del CNL" }
      },
      {
        path: "detalleVideos/:id",
        component: VideoDetalleComponent,
        data: { titulo: "Resumen de Videos" }
      },

      {
        path: "preguntas",
        component: PreguntasComponent,
        data: { titulo: "Consultas" }
      },
      {
        path: "quejas-sugerencias",
        component: QuejasComponent,
        data: { titulo: "Quejas y Sugerencias" }
      },
      //JR
      {
        path: "archivo-exnotarios",
        component: ExnotariosComponent,
        data: { titulo: "Archivo Ex Notarios" }
      },
      {
        path: "trabajacnl",
        component: TrabajacnlComponent,
        data: { titulo: "Trabaja con Nosotros" }
      },
      {
        path: "detalletrabajo",
        component: DetalletrabajoComponent,
        data: { titulo: "Detalle Convocatoria" }
      },{
        path: "detalletrabajoaux____old",
        component: DetalletrabajonewComponent,
        data: { titulo: "Detalle Convocatoria" }
      },
      {
        path: "detalletrabajoprogramador",
        component: DetalletrabajoprogramadorComponent,
        data: { titulo: "Detalle Convocatoria" }
      },
      


      

      {
        path: "congreso/:boletin.id",
        component: CongresoComponent,
        data: { titulo: "Decano CNL en el Congreso de la República" }
      },
      
      
      //
      {
        path: "ConvocatoriaOCP",
        component: ElnotarioComponent,
        data: { titulo: "Convocatoria" }
      },



      {
        path: "ConvocatoriaAdministrador",
        component: ConadministradorComponent,
        data: { titulo: "Convocatoria" }
      },

      {
        path: "ConvocatoriaTribunal",
        component: ConsecretribunalComponent,
        data: { titulo: "Convocatoria" }
      },




      {
        path: "EventoLibro",
        component: EventolibroComponent,
        data: { titulo: "Evento" }
      },

      







      {
        path: "Proyectos",
        component: ConvproyComponent,
        data: { titulo: "Convocatoria" }
      },

      {
        path: "concursoocp",
        component: ConcursoocpComponent,
        data: { titulo: "Convocatoria" }
      },
      {
        path: "matrimonio",
        component: MatrimonioComponent,
        data: { titulo: "Matrimonio Civil" }
      },
      {
        path: "ceas",
        component: CeasComponent,
        data: { titulo: "CONVENIO" }
      },

      {
        path: "Reconocimientodehijo",
        component: ReconocimientohijoComponent,
        data: { titulo: "Campaña" }
      },

      



      {
        path: "congresointernacional",
        component: CongresoiComponent,
        data: { titulo: "CONGRESO INTERNACIONAL" }
      },
      
      {
        path: "solicitudreniec",
        component: ReniecComponent,
        data: { titulo: "INFORME SOBRE SOLICITUD AL RENIEC" }
      },

      {
        path: "curso",
        component: ConvocatoriaComponent,
        data: { titulo: "Curso" }
      },
      {
        path: "concurso",
        component: ConcursocnlComponent,
        data: { titulo: "Concurso" }
      },
      {
        path: "concursoflorales",
        component: ConcursofloresComponent,
        data: { titulo: "Concurso Juegos Florares" }
      },
      {
        path: "funciones",
        component: FuncionesComponent,
        data: { titulo: "Notarios de Lima en Funciones" }
      },
      {
        path: "comunicado",
        component: ComunicadoComponent,
        data: { titulo: "Comunicado" }
      },
      {
        path: "comunicados",
        component: ComunicadosComponent,
        data: { titulo: "Comunicado" }
      },
      {
        path: "comunicadostrib",
        component: ComunicadostribComponent,
        data: { titulo: "Comunicado" }
      },
      {
        path: "comunicacion",
        component: ComunicacionComponent,
        data: { titulo: "Comunicado Plazos Procedimientos no Contenciosos" }
      },

      {
        path: "comunicadonotarios",
        component: ComunicaComponent,
        data: { titulo: " " }
      },

      {
        path: "galeria/:id/:pagina",
        component: GaleriaComponent,
        data: { titulo: "Galeria" }
      },
      {
        path: "detalleGaleria/:id",
        component: GaleriaDetalleComponent,
        data: { titulo: "Detalle de Galeria" }
      },


      {
        path: "eventosacademicos/:id/:pagina",
        component: EventosAcademicosComponent,
        data: { titulo: "Eventos Académicos" }
      },




      {
        path: "listado/:id/:pagina",
        component: PrensaListadoComponent,
        data: { titulo: "Notas de Prensa" }
      },

      {
        path: "movil",
        component: MovilComponent,
        data: { titulo: "Aplicación Móvil" }
      },

      {
        path: "deporte",
        component: DeporteComponent,
        data: { titulo: "Copa Confraternidad Notarial" }
      },
      {
        path: "deporteGaleria/:id",
        component: DeporteGaleriaComponent,
        data: { titulo: "Galeria del Evento" }
      },

      // DISCURSO
      {
        path: "discursos",
        component: DiscursosComponent,
        data: { titulo: "Discurso de Orden" }
      },

      // NOTARIOS
      {
        path: "notarios",
        component: NotariosComponent,
        data: { titulo: "Listado de Notarios" }
      },
      {
        path: "listaNotarios/:id/:pagina",
        component: NotariosTodosComponent,
        data: { titulo: "Listado de Notarios" }
      },
      {
        path: "listaprecios/:id/:pagina",
        component: ListaPreciosComponent,
        data: { titulo: "Relación actualizada de notarios según Ley 30908" }
      },
      {
        path: "informate-y-compara",
        component: ListaPrecioComponent,
        data: { titulo: "Relación actualizada de notarios según Ley 30908" }
      },
      {
        path: "listaprecio/20/5",
        component: ListaPrecioComponent,
        data: { titulo: "Relación actualizada de notarios según Ley 30908" }
      },
      {
        path: "listaContacto/:id",
        component: ListaContactosComponent,
        data: { titulo: "Listado de Contactos" }
      },

      {
        path: "libros",
        component: LibrosComponent,
        data: { titulo: "Fondo Editorial CNL / Publicaciones" }
      },
      {
        path: "libroDetalles/:id",
        component: LibroDetallesComponent,
        data: { titulo: "Detalles Fondo Editorial CNL" }
      },

      { path: "", redirectTo: "home", pathMatch: "full" }
    ]
  }
];

// tslint:disable-next-line:eofline
export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
