import { Validators } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Quejas } from '../../models/quejas';
import { QuejasService } from '../../services/quejas/quejas.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-quejas',
  templateUrl: './quejas.component.html',
  styles: []
})
export class QuejasComponent implements OnInit {

  forma: FormGroup;

  constructor(private quejasService: QuejasService) { }

  ngOnInit() {
    this.forma = new FormGroup({
      nombres: new FormControl(null, Validators.required),
      dni: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('[0-9]+')]),
      correo: new FormControl(null, [Validators.required, Validators.email]),
      asunto: new FormControl(null, Validators.required),
      mensaje: new FormControl(null, Validators.required),
      telefono: new FormControl(null, Validators.required),
    });
  }

  enviarMensaje(){
    
    if (this.forma.invalid) {
      return;
    }

    let fecha:any = new Date();
    if (this.forma.value.telefono === undefined) {
      this.forma.value.telefono = '';
    }
    let mensaje = new Quejas(this.forma.value.nombres, this.forma.value.dni, this.forma.value.correo, this.forma.value.asunto, this.forma.value.telefono, this.forma.value.mensaje, fecha);


    console.log(mensaje);
    this.quejasService.enviarMensaje(mensaje).subscribe(response=>{
      swal('Mensaje', response.message, response.status);
      this.forma.reset();
    }, error=>{
      console.log(error);
    });

  }

}
