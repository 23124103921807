import { Injectable } from '@angular/core';
import { urlBackend } from '../../models/GLOBAL';
import { Observable } from 'rxjs-compat';
import { Quejas } from '../../models/quejas';
import { Mensajes } from '../../models/mensajes';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class QuejasService {

  url: string = urlBackend;
  urll: String="https://mpv.notarios.org.pe/apirest/";
  //urll: String="http://localhost:8099/apirest/";
  constructor(public http: HttpClient) { }

  enviarMensaje(quejas: Quejas):Observable<any>{
    let json = JSON.stringify(quejas);
    return this.http.post(this.urll + 'quejas',json);
  }

  enviarMensajexnotario(quejas: Mensajes):Observable<any>{
    let json = JSON.stringify(quejas);
    return this.http.post(this.urll + 'mensajes',json);
  }


  enviarMensajexnotarionuevo(quejas: Mensajes, archivo:any,puesto:any):Observable<any>{

    let iJson={"quejas":quejas,'archivo':archivo,'puesto':puesto};
    let json = JSON.stringify(iJson);
    return this.http.post(this.urll + 'mensajes',json);
   
  }

  

}
