import { Component, OnInit, DoCheck } from '@angular/core';
import { Galeria } from '../../models/galeria';
import { GaleriaService } from '../../services/service.index';
import { ImagenesGaleria } from '../../models/imagenesGaleria';
import { ActivatedRoute } from '@angular/router';
import { EventosService } from '../../services/eventos/eventos.service';
declare function init_slider();
declare function init_imagen();
declare var $: any;

@Component({
  selector: 'app-galeria-detalle',
  templateUrl: './galeria-detalle.component.html',
  styles: []
})
export class GaleriaDetalleComponent implements OnInit, DoCheck {

  galeria: Galeria = new Galeria();
  imagenes: ImagenesGaleria = new ImagenesGaleria();
  total: number;
  totalImagen: number;
  url: string;
  urlImagen: string;
  mostrar:string;
  eventos: any[] = [];

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
    previousLabel: 'Anterior',
    nextLabel: 'Siguiente'
  };

  constructor(
    public galeriaService: GaleriaService,
    public activatedRoute: ActivatedRoute,
    private eventosService: EventosService
  ) {
    this.url = galeriaService.urlUpload + 'galeria/';
    this.urlImagen = galeriaService.urlUpload + 'imagenesGaleria/';
  }

  ngOnInit() {
    init_slider();
    init_imagen();
    this.obtenerEventos();
    this.activatedRoute.params.subscribe(params=>{
      let codigo = params['id'];
      this.obtenerGaleria(codigo);
      this.obtenerImagenes(codigo);
    })

  }

  ngDoCheck(){
    init_slider();
    $(".acm").nivoLightbox({
      effect: "fade",
      theme: "default",
      keyboardNav: !0,
      errorMessage:
        "El contenido solicitado no se puede cargar. Por favor, inténtelo de nuevo más tarde."
    });
  }

  cargarScript(){
    setTimeout(() => {
      $(".acm").nivoLightbox({
        effect: "fade",
        theme: "default",
        keyboardNav: !0,
        errorMessage:
          "El contenido solicitado no se puede cargar. Por favor, inténtelo de nuevo más tarde."
      });
    }, 1);
  }

  obtenerGaleria(id:string){
    this.galeriaService.obtenerGaleria(id).subscribe(response=>{
      this.galeria = response.galerias;
      this.total = response.total;
      this.cargarScript();
    });
  }

  obtenerImagenes(idGaleria:string){
    this.galeriaService.obtenerImagen(idGaleria).subscribe(response=>{
      this.imagenes = response.imagenes;
      this.totalImagen = response.total;
      this.cargarScript();
    })
  }

  obtenerEventos(){
    this.eventosService.cargarEventos().subscribe(response=>{
      this.eventos = response.eventos;
      this.eventos.forEach(event=>{
        if (event.mostrar === 1) {
          this.mostrar = "1";
        }else{
          this.mostrar = "0";
        }
      });
    });
  }

}
