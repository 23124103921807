import { VideosService } from '../../services/service.index';
import { Component, OnInit, DoCheck } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Videos } from '../../models/videos';
import { EventosService } from '../../services/eventos/eventos.service';
import { Eventos } from '../../models/eventos';
declare function init_slider();

@Component({
  selector: 'app-video-detalle',
  templateUrl: './video-detalle.component.html',
  styles: []
})
export class VideoDetalleComponent implements OnInit, DoCheck {

  videos: Videos;
  total: number;
  opciones: string = '?rel=0&amp;wmode=transparent';
  mostrar:string;
  eventos: any[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private videoService: VideosService,
    private eventosService: EventosService
  ) {}

  ngOnInit() {
    this.obtenerEventos();
    this.activatedRoute.params.subscribe(params=>{
      let codigo = params['id'];
      this.videoService.obtenerVideoDetalle(codigo).subscribe(response=>{
        this.videos = response.videos;
        this.total = response.total;
        init_slider();
      });
    });
  }

  ngDoCheck(){
    init_slider();
  }

  obtenerEventos(){
    this.eventosService.cargarEventos().subscribe(response=>{
      this.eventos = response.eventos;
      this.eventos.forEach(event=>{
        if (event.mostrar === 1) {
          this.mostrar = "1";
        }else{
          this.mostrar = "0";
        }
      });
    });
  }

}
