import { RouterModule, Routes } from '@angular/router';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { FacturasComponent } from './facturas/facturas.component';

const app_routes:Routes=[
  {path: '404', component: NopagefoundComponent },
  {path: 'facturacion', component: FacturasComponent, data: { titulo: 'Consulta de Documentos Electrónicos'} },
  {path: '***', redirectTo: '404', pathMatch: 'full'}
];

export const app_routing=RouterModule.forRoot(app_routes, {useHash: true});
