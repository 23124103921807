import { Component, OnInit } from '@angular/core';
import { IconosService } from './../../services/iconos/iconos.service';
import { Iconos } from './../../models/iconos';
import { urlBackendUpload } from 'src/app/models/GLOBAL';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styles: []
})
export class IconComponent implements OnInit {

  iconos: Iconos[] = [];
  url: string = urlBackendUpload + 'iconos/';

  constructor(public iconosService: IconosService) { }

  ngOnInit() {
    this.cargarIconos();
  }

  cargarIconos(){
    this.iconosService.cargarIconos().subscribe(response=>{
      this.iconos = response.iconos;
    });
  }

}
