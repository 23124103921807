import { Component, OnInit } from '@angular/core';
import { LibrosService } from 'src/app/services/libros/libros.service';
import { Libros } from 'src/app/models/libros';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Router } from '@angular/router';

@Component({
  selector: 'app-libros',
  templateUrl: './libros.component.html',
  styles: []
})
export class LibrosComponent implements OnInit {

  librosDigital: Libros[] = [];
  librosImpresas: Libros[] = [];
  totalDigital: number = 0;
  totalImpresas: number = 0;
  url: string = urlBackendUpload + 'libros/';
  ruta1: string = urlBackendUpload;

  constructor(public librosService: LibrosService, public router:Router) { }

  ngOnInit() {
    this.cargarLibrosDigital();
    this.cargarLibrosImpresas();
  }

  cargarLibrosDigital(){
    this.librosService.cargarLibrosDigital().subscribe(response=>{
      this.librosDigital = response.libros;
      this.totalDigital = response.total;
    });
  }

  cargarLibrosImpresas(){
    this.librosService.cargarLibrosImpresas().subscribe(response=>{
      this.librosImpresas = response.libros;
      this.totalImpresas = response.total;
    });
  }

  detalle(libroId:number){
    this.router.navigate(['/libroDetalles', libroId]);
  }

  libroDigital(ruta: string){
    window.open(this.ruta1 + ruta + '/index.html', 'popup', 'width=1000px,height=800px');
  }

}
