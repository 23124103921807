import { EntidadesService } from '../../services/service.index';
import { Component, OnInit } from '@angular/core';
import { Entidad } from '../../models/entidades';

@Component({
  selector: 'app-entidades',
  templateUrl: './entidades.component.html',
  styles: []
})
export class EntidadesComponent implements OnInit   {

  entidades: Entidad[] = [];
  total: number;
  url: string;

  constructor(public entidadService: EntidadesService) {
    this.url = entidadService.urlUpload + 'entidades/';
  }

  ngOnInit() {
    this.entidadService.cargarSlider().subscribe(response=>{
      this.entidades = response.entidades;
      this.total = response.total;
    });
  }
}
