import { Component, OnInit } from '@angular/core';
import { Libros } from 'src/app/models/libros';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { LibrosService } from 'src/app/services/libros/libros.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-libro-detalles',
  templateUrl: './libro-detalles.component.html',
  styleUrls: []
})
export class LibroDetallesComponent implements OnInit {

  url: string = urlBackendUpload + 'libros/';
  ruta1: string = urlBackendUpload;
  libro: Libros = new Libros();

  constructor(
    public librosService: LibrosService,
    public activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params=>{
        let codigo = params["id"];
        this.obtenerLibros(codigo);
    });
  }

  obtenerLibros(libroId: number){
    this.librosService.obtenerLibros(libroId).subscribe(response=>{
      this.libro = response.libros;
    });
  }

  libroDigital(ruta: string){
    window.open(this.ruta1 + ruta + '/index.html', 'popup', 'width=1000px,height=800px');
  }

}
