export class Libros {
  constructor(
    public id?:number,
    public user_id?: number,
    public titulo?: string,
    public autor?: string,
    public resumen?: string,
    public publicacion?: string,
    public precio?:number,
    public tipo?: number,
    public url?: string,
    public imagen?: string,
    public orden?: number,
    public status?: number
  ) {}
}
