import { urlBackend } from '../../models/GLOBAL';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaginasService {

  url:string = urlBackend

  constructor(public http: HttpClient) { }

  cargarPaginasMenu(id:string):Observable<any>{
    return this.http.get(this.url + 'paginas/menu/' + id);
  }

  cargarPaginasSubMenu(id:string):Observable<any>{
    return this.http.get(this.url + 'paginas/submenu/' + id);
  }
}
