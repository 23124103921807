import { Eventos } from '../../models/eventos';
import { Component, OnInit } from '@angular/core';
import { EventosService } from '../../services/service.index';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styles: []
})
export class EventosComponent implements OnInit {

  eventos: Eventos[] = [];
  total: number;
  url: string;

  constructor(public eventosService: EventosService) {
    this.url = eventosService.url + 'storage/eventos/';
  }

  ngOnInit() {
    this.eventosService.cargarEventos().subscribe(response=>{
      this.eventos = response.eventos;
      this.total = response.total;
    });
  }

}
