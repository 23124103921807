import { Component, OnInit, DoCheck } from '@angular/core';
import { DeporteService } from './../../services/deporte/deporte.service';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { Deporte } from 'src/app/models/deporte';
import { DeporteDetalle } from './../../models/deporteDetalle';
import { DeporteGaleria } from './../../models/deporteGaleria';
import { EventosService } from './../../services/eventos/eventos.service';
import { Router } from '@angular/router';
declare function init_slider();
declare function init_imagen();
declare var $:any;

@Component({
  selector: "app-deporte",
  templateUrl: "./deporte.component.html",
  styles: []
})
export class DeporteComponent implements OnInit, DoCheck {
  url: string = urlBackendUpload + "deporte/";
  urlDetalle: string = urlBackendUpload + "deporteDetalle/";
  urlGaleria: string = urlBackendUpload + "deporteGaleria/";
  deporte: Deporte = new Deporte();
  deporteDetalle: DeporteDetalle[] = [];
  deporteGaleria: DeporteGaleria[] = [];

  mostrar: string;
  eventos: any[] = [];

  constructor(
    public deporteService: DeporteService,
    private eventosService: EventosService,
    public router: Router
  ) {}

  ngOnInit() {
    init_slider();
    init_imagen();
    this.cargarDeporte();
    this.obtenerEventos();
  }

  cargarScript() {
    setTimeout(() => {
      $(".acm").nivoLightbox({
        effect: "fade",
        theme: "default",
        keyboardNav: !0,
        errorMessage:
          "El contenido solicitado no se puede cargar. Por favor, inténtelo de nuevo más tarde."
      });
    }, 1);
  }

  cargarDeporte() {
    this.deporteService.cargarDeporte().subscribe(response => {
      this.deporte = response.deportes;
      this.cargarDeporteDetalle(this.deporte.id);
      this.cargarDeporteGaleria(this.deporte.id);
      this.cargarScript();
    });
  }

  cargarDeporteDetalle(deporteId: number) {
    this.deporteService.cargarDeporteDetalle(deporteId).subscribe(response => {
      this.deporteDetalle = response.deportes;
      this.cargarScript();
    });
  }

  cargarDeporteGaleria(deporteId: number) {
    this.deporteService.cargarDeporteGaleria(deporteId).subscribe(response => {
      this.deporteGaleria = response.deportes;
      this.cargarScript();
    });
  }

  ngDoCheck() {
    init_slider();
  }

  detalle(galeriaId:number){
    this.router.navigate(['/deporteGaleria', galeriaId]);
    this.cargarScript();
  }

  obtenerEventos() {
    this.eventosService.cargarEventos().subscribe(response => {
      console.log('fffffffffffffff');
      console.log(response);
      this.mostrar = "1";
      this.eventos = response.eventos;
      this.eventos.forEach(event => {
        if (event.mostrar === 1) {
          this.mostrar = "1";
        } else {
          this.mostrar = "0";
        }
      });
    });
  }
}
