import { Injectable } from '@angular/core';
import { urlBackend } from '../../models/GLOBAL';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LegislacionService {

  url: string = urlBackend;

  constructor(public http: HttpClient) { }

  cargarLegislacion():Observable<any>{
    return this.http.get(this.url + 'legislacion');
  }

  cargarCategorias():Observable<any>{
    return this.http.get(this.url + 'legislacion/listCategoria');
  }

  legislacionXCategoria():Observable<any>{
    return this.http.get(this.url + 'legislacion/legislacionXCategoria');
  }

  busqueda(termino:string):Observable<any>{
    return this.http.get(this.url + 'legislacion/busqueda/'+ termino);
  }
}
