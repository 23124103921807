import { Component, OnInit, DoCheck } from '@angular/core';
import { Videos } from '../../models/videos';
import { VideosService } from '../../services/service.index';
import { EventosService } from '../../services/eventos/eventos.service';
import { Eventos } from '../../models/eventos';
declare function init_slider();

@Component({
  selector: 'app-prensa-videos',
  templateUrl: './prensa-videos.component.html',
  styles: []
})
export class PrensaVideosComponent implements OnInit, DoCheck {

  mostrar:string;
  eventos: any[] = [];

  // PAGINADO
  p: number = 1;
  public autoHide: boolean = true;
  public labels: any = {
        previousLabel: 'Anterior',
        nextLabel: 'Siguiente'
    };

  videos: Videos[] = [];
  total: number;
  url: string;

  constructor(public videosService: VideosService, private eventosService: EventosService) {
    this.url = 'https://i.ytimg.com/vi/';
  }

  ngOnInit() {
    this.obtenerEventos();
    this.videosService.cargarVideos().subscribe(response=>{
      this.videos = response.videos;
      this.total = response.total;
      init_slider();
    });
  }

  ngDoCheck(){
    init_slider();
  }

  obtenerEventos(){
    this.eventosService.cargarEventos().subscribe(response=>{
      this.eventos = response.eventos;
      this.eventos.forEach(event=>{
        if (event.mostrar === 1) {
          this.mostrar = "1";
        }else{
          this.mostrar = "0";
        }
      });
    });
  }

}
