export class Notarios {
  constructor(
    public id?:number,
    public notario?: string,
    public direccion?: string,
    public horario?: string,
    public telefono?: string,
    public correo?: string,
    public web?: string,
    public ubigeo?: string,
    public latitud?: string,
    public longitud?: string,
    public estado?: number, //0 activo - 1 cesado - 2 licencia
    public created_at?: string,
    public updated_at?: string,
    public colegiatura?: string,
    public nomdis?:string
  ) {}
}
