import { Component, OnInit } from '@angular/core';
import { urlBackendUpload } from 'src/app/models/GLOBAL';
import { DiscursoService } from './../../services/discurso/discurso.service';
import { Discurso } from './../../models/discurso';
import { Mostrar } from './../../models/mostrar';

@Component({
  selector: "app-discurso",
  templateUrl: "./discurso.component.html",
  styles: []
})
export class DiscursoComponent implements OnInit {
  url: string = urlBackendUpload + "discursos/";
  discurso: Discurso = new Discurso();
  mostrar: Mostrar = new Mostrar();
  total: number = 0;

  constructor(public discursoService: DiscursoService) {}

  ngOnInit() {
    this.cargarDiscurso();
    this.cargarDatos();
  }

  cargarDiscurso() {
    this.discursoService.cargarDiscurso().subscribe(response => {
      this.discurso = response.discursos;
      this.total = response.total;
    });
  }

  cargarDatos() {
    this.discursoService.cargarDatos().subscribe(response => {
      this.mostrar = response.mostrar;
    });
  }
}
